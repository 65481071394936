import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
/*
const Dashboard = () => import('@/views/Dashboard')
*/

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const ResetPassword = () => import('@/views/pages/Reset')

// MY
const Dashboard = () => import('@/views/Dashboard')

const Agenda = () => import('@/views/turnos/Agenda')
const AgendaProfesional = () => import('@/views/turnos/AgendaProfesional')
const AgendaSala = () => import('@/views/turnos/AgendaSala')
const Areas = () => import('@/views/areas/List')
const AreaAdd = () => import('@/views/areas/ABM')
const AreaEdit = () => import('@/views/areas/ABM')
const DiasNoLaborables = () => import('@/views/diasNoLaborables/List')
const DiasNoLaborablesAPI = () => import('@/views/diasNoLaborables/ApiList')
const Horario = () => import('@/views/horarios/ABM')
const HorarioPublico = () => import('@/views/horarios/ABM')
const Pacientes = () => import('@/views/pacientes/List')
const PacienteAdd = () => import('@/views/pacientes/ABM')
const PacienteDatos = () => import('@/views/pacientes/Data')
const PacienteEdit = () => import('@/views/pacientes/ABM')
const PacienteHistoriaClinicaAdd = () => import('@/views/historiaClinica/ABM')
const PacienteHistoriaClinicaEdit = () => import('@/views/historiaClinica/ABM')
const PacienteHistoriaClinicaDatos = () => import('@/views/historiaClinica/Data')
const Profesionales = () => import('@/views/profesionales/List')
const ProfesionalAdd = () => import('@/views/profesionales/ABM')
const ProfesionalDatos = () => import('@/views/profesionales/Data')
const ProfesionalEdit = () => import('@/views/profesionales/ABM')
const Salas = () => import('@/views/salas/List')
const SalaAdd = () => import('@/views/salas/ABM')
const SalaEdit = () => import('@/views/salas/ABM')
const Servicios = () => import('@/views/servicios/List')
const ServicioAdd = () => import('@/views/servicios/ABM')
const ServicioEdit = () => import('@/views/servicios/ABM')
const Tratamientos = () => import('@/views/tratamientos/List')
const TratamientoAdd = () => import('@/views/tratamientos/ABM')
const TratamientoDatos = () => import('@/views/tratamientos/Data')
const TratamientoEdit = () => import('@/views/tratamientos/ABM')
const Turnos = () => import('@/views/turnos/List')
// const ArticuloData = () => import('@/views/articulos/Data')

const Usuarios = () => import('@/views/usuarios/List')
const UsuarioABM = () => import('@/views/usuarios/ABM')
const UsuarioData = () => import('@/views/usuarios/Data')
const UsuariosNoHabilitados = () => import('@/views/usuarios/NoHabilitados')
const UsuarioPerfil = () => import('@/views/usuarios/Perfil')
const UsuarioPass = () => import('@/views/usuarios/Pass')

Vue.use(Router)

function requireAuth (to, from, next) {
  if ( !store.getters['user/loggedIn'] ) {
    // console.log('!loggedIn');
    store.dispatch('user/tryLogin')
      .then(() => {
        /* Logueamos a partir de las credenciales del localStorage */
        next ();  
      })
      .catch(() => {
        /* No hay un usuario logueado, redirigimos al login */
        next ('/login');
      });     
  } else {
    // console.log('loggedIn');
    if ( !store.getters['user/tieneAccesoRuta'](to) ) {
      /* No tiene permiso, se redirige al home */
      next ('/');
    } else {
      /* Acceso habilitado */
      next ();
    }    
  }
}

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  // mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/inicio',
      name: 'Home',
      meta: { label: 'Inicio'},
      component: DefaultContainer,
      children: [
        {
          path: 'inicio',
          name: 'Solestic',
          meta: { label: 'Inicio'},
          component: Dashboard,
          beforeEnter: requireAuth
        },

        {
          path: 'agenda',
          meta: { label: 'Agenda'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'turnos',
              meta: { label: 'Agenda'},
              // name: 'Turnos',
              // component: Turnos,
              name: 'Agenda',
              component: Agenda,
              beforeEnter: requireAuth,
            },
            {
              path: 'profesionales',
              meta: { label: 'Agenda - Profesional'},
              name: 'AgendaProfesional',
              component: AgendaProfesional,
              beforeEnter: requireAuth,
            },
            {
              path: 'salas',
              meta: { label: 'Agenda - Sala'},
              name: 'AgendaSala',
              component: AgendaSala,
              beforeEnter: requireAuth,
            },
          ]
        },

        {
          path: 'salas',
          meta: { label: 'Salas'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Salas',
              meta: { label: 'Listado de Salas'},
              component: Salas,
              beforeEnter: requireAuth,
            },
            {
              path: 'add',
              meta: { label: 'Información de la Sala'},
              name: 'SalaAdd',
              component: SalaAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información de la Sala'},
              name: 'SalaEdit',
              component: SalaEdit,
              beforeEnter: requireAuth,
            },
          ]
        },
        {
          path: 'areas',
          meta: { label: 'Areas'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Areas',
              meta: { label: 'Listado de Areas'},
              component: Areas,
              beforeEnter: requireAuth,
            },
            {
              path: 'add',
              meta: { label: 'Información del Area'},
              name: 'AreaAdd',
              component: AreaAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información del Area'},
              name: 'AreaEdit',
              component: AreaEdit,
              beforeEnter: requireAuth,
            },
          ]
        },
        {
          path: 'servicios',
          meta: { label: 'Servicios'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Servicios',
              meta: { label: 'Listado de Servicios'},
              component: Servicios,
              beforeEnter: requireAuth,
            },
            {
              path: 'add',
              meta: { label: 'Información del Servicio'},
              name: 'ServicioAdd',
              component: ServicioAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información del Servicio'},
              name: 'ServicioEdit',
              component: ServicioEdit,
              beforeEnter: requireAuth,
            },
          ]
        },
        {
          path: 'tratamientos',
          meta: { label: 'Tratamientos'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Tratamientos',
              meta: { label: 'Listado de Tratamientos'},
              component: Tratamientos,
              beforeEnter: requireAuth,
            },
            {
              path: 'add',
              meta: { label: 'Información del Tratamiento'},
              name: 'TratamientoAdd',
              component: TratamientoAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id',
              meta: { label: 'Información del Tratamiento'},
              name: 'TratamientoDatos',
              component: TratamientoDatos,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información del Tratamiento'},
              name: 'TratamientoEdit',
              component: TratamientoEdit,
              beforeEnter: requireAuth,
            },
          ]
        },

        {
          path: 'profesionales',
          meta: { label: 'Profesionales'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Profesionales',
              meta: { label: 'Listado de Profesionales'},
              component: Profesionales,
              beforeEnter: requireAuth,
            },
            {
              path: 'add',
              meta: { label: 'Información del Profesional'},
              name: 'ProfesionalAdd',
              component: ProfesionalAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id',
              meta: { label: 'Información del Profesional'},
              name: 'ProfesionalDatos',
              component: ProfesionalDatos,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información del Profesional'},
              name: 'ProfesionalEdit',
              component: ProfesionalEdit,
              beforeEnter: requireAuth,
            },
          ]
        },

        {
          path: 'pacientes',
          meta: { label: 'Pacientes'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              meta: { label: 'Listado de Pacientes'},
              name: 'Pacientes',
              component: Pacientes,
              beforeEnter: requireAuth,
            },
            {
              path: 'add',
              meta: { label: 'Información del Paciente'},
              name: 'PacienteAdd',
              component: PacienteAdd,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/edit',
              meta: { label: 'Información del Paciente'},
              name: 'PacienteEdit',
              component: PacienteEdit,
              beforeEnter: requireAuth,
            },
            {
              path: ':id',
              meta: { label: 'Información del Paciente'},
              component: {
                render (c) { return c('router-view') }
              },
              beforeEnter: requireAuth,
              children: [
                {
                  path: '',
                  meta: { label: 'Información del Paciente'},
                  name: 'PacienteDatos',
                  component: PacienteDatos,
                  beforeEnter: requireAuth,
                },
                {
                  path: 'historia-clinica',
                  meta: { label: 'Historia Clínica'},
                  component: {
                    render (c) { return c('router-view') }
                  },
                  beforeEnter: requireAuth,
                  children: [
                    {
                      path: 'add',
                      meta: { label: 'Entrada'},
                      name: 'PacienteHistoriaClinicaAdd',
                      component: PacienteHistoriaClinicaAdd,
                      beforeEnter: requireAuth
                    },
                    {
                      path: ':historiaClinicaEntradaId/edit',
                      meta: { label: 'Entrada'},
                      name: 'PacienteHistoriaClinicaEdit',
                      component: PacienteHistoriaClinicaEdit,
                      beforeEnter: requireAuth
                    },
                    {
                      path: ':historiaClinicaEntradaId',
                      meta: { label: 'Entrada'},
                      name: 'PacienteHistoriaClinicaDatos',
                      component: PacienteHistoriaClinicaDatos,
                      beforeEnter: requireAuth
                    },
                  ]
                }
              ]
            }
          ]
        },

        {
          path: 'horario',
          meta: { label: 'Horario'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'atencion',
              meta: { label: 'Horario de Atención'},
              name: 'Horario',
              component: Horario,
              beforeEnter: requireAuth,
              props: { horarioPublico: false }
            },
            {
              path: 'publico',
              meta: { label: 'Horario de Atención Público'},
              name: 'HorarioPublico',
              component: HorarioPublico,
              beforeEnter: requireAuth,
              props: { horarioPublico: true }
            },
          ]
        },

        {
          path: 'no-laborables',
          meta: { label: 'Dias No Laborables'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              meta: { label: 'Calendario'},
              name: 'DiasNoLaborables',
              component: DiasNoLaborables,
              beforeEnter: requireAuth,
            },
            // {
            //   path: '',
            //   meta: { label: 'Calendario Externo'},
            //   name: 'DiasNoLaborablesAPI',
            //   component: DiasNoLaborablesAPI,
            //   beforeEnter: requireAuth,
            // },
          ]
        },

        {
          path: 'usuarios',
          meta: { label: 'Usuarios'},
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: requireAuth,
          children: [
            {
              path: '',
              name: 'Usuarios',
              component: Usuarios,
              beforeEnter: requireAuth
            },
            {
              path: 'no-habilitados',
              name: 'UsuariosNoHabilitados',
              component: UsuariosNoHabilitados,
              beforeEnter: requireAuth
            },
            {
              path: 'pass',
              meta: { label: 'Actualizar Password'},
              name: 'UsuarioPass',
              component: UsuarioPass,
              beforeEnter: requireAuth,
              props: true
            },
            {
              path: 'perfil',
              meta: { label: 'Datos del Usuario'},
              name: 'UsuarioPerfil',
              component: UsuarioPerfil,
              beforeEnter: requireAuth,
            },
            {
              path: ':id/ABM',
              meta: { label: 'Datos del Usuario'},
              name: 'UsuarioABM',
              component: UsuarioABM,
              beforeEnter: requireAuth,
            },
            {
              path: ':id',
              meta: { label: 'Datos del Usuario'},
              name: 'UsuarioData',
              component: UsuarioData,
              beforeEnter: requireAuth,
            },
          ]
        },
      ]
    },

    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        // {
        //   path: 'login',
        //   name: 'Login',
        //   component: Login
        // },
        // {
        //   path: 'register',
        //   name: 'Register',
        //   component: Register
        // }
      ]
    },

    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/registro',
      name: 'Register',
      component: Register
    },
    {
      path: '/reset-pwd',
      // path: '/reset-pwd/:token',
      // query: { }
      name: 'ResetPassword',
      component: ResetPassword
    },

  ]
})
