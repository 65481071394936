import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    email: '',
    apellido: '',
    apellido2: '',
    nombre: '',
    usuarioRolId: 0,
    usuarioRol: null,
    usuarioRolNombre: null,
    inactivo: false,
    rowVersion: null,
    
    usuarioDatos: [],
};

const mutations = {
    updateField,

    'INICIALIZAR_USUARIO'(state, usuarioId) {
        state.id = usuarioId;
        state.email = '';
        state.apellido = '';
        state.apellido2 = '';
        state.nombre = '';
        state.usuarioRolId = 0;
        state.usuarioRol = null;
        state.usuarioRolNombre = null;
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_USUARIO'(state, usuario) {
        state.email = usuario.email.toLowerCase().trim();
        state.apellido = usuario.apellido.trim();
        state.apellido2 = usuario.apellido2.trim();
        state.nombre = usuario.nombre.trim();
        state.usuarioRol = {
            id: usuario.usuarioRolId,
            nombre: usuario.usuarioRol
        };
        state.usuarioRolId = usuario.usuarioRolId;
        state.usuarioRolNombre = usuario.usuarioRol.trim();
        state.inactivo = usuario.inactivo;
        state.rowVersion = usuario.rowVersion;
    },
    'SET_USUARIO_ROL' (state, usuarioRol) {
        state.usuarioRol = usuarioRol;
        if (usuarioRol) {
            state.usuarioRolId = usuarioRol.id? usuarioRol.id : 0;
            state.usuarioRolNombre = usuarioRol.nombre? usuarioRol.nombre.trim() : null;
        } else {
            state.usuarioRolId = 0;
            state.usuarioRolNombre = null;
        }
    },

    'INICIALIZAR_DATOS' (state) {
        state.usuarioDatos = [];
    },
    'SET_USUARIO_DATOS' (state, datos) {
        state.usuarioDatos = datos;
    }
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit }, usuarioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_USUARIO', usuarioId);

            if (usuarioId != 0) {
                apiAxios.get('usuarios/' + usuarioId + '/abm' ) 
                    .then(res => {
                        commit('SET_USUARIO', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },    
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let usuario= {
                // email: state.email.toLowerCase().trim(),
                apellido: state.apellido.trim(),
                apellido2: state.apellido2.trim(),
                nombre: state.nombre.trim(),
                usuarioRolId: parseInt(state.usuarioRolId),
                inactivo: state.inactivo,
            }
            let url = 'usuarios';
            if (state.id != 0) {
                usuario.rowVersion = state.rowVersion;
                url += '/' + state.id;
            }

            apiAxios.post(url, usuario)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    setUsuarioRol: ({commit}, usuarioRol) => {
        return new Promise((resolve, reject) => {
            commit('SET_USUARIO_ROL', usuarioRol);
            resolve();
        })
    },
    activar ( {}, usuarioId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('usuarios/' + usuarioId + '/activar')
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    inactivar ( {}, usuarioId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('usuarios/' + usuarioId + '/inactivar')
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    resetPassword ( {}, params ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('usuarios/reset-password', params)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    forgotPassword ( {}, usuarioId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('usuarios/' + usuarioId + '/forgot')
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    preregistrar ( {}, params ) {
        return new Promise((resolve, reject) => { 
          apiAxios.post('usuarios/preregistro', params)
            .then(() => resolve())
            .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getDatos ( { commit }, usuarioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DATOS');

            if (usuarioId != 0) {
                apiAxios.get('usuarios/' + usuarioId ) 
                .then(res => {
                    var datos = [
                        { 'nombre': 'email', 'campo': 'Email', 'valor': res.data.email, 'formato': '' },
                        { 'nombre': 'nombre', 'campo': 'Nombre', 'valor': res.data.nombre, 'formato': '' },
                        { 'nombre': 'inactivo', 'campo': 'Inactivo', 'valor': (res.data.inactivo) ? 'Si' : 'No', 'formato': '' },
                        { 'nombre': 'sysAdmin', 'campo': 'SysAdmin', 'valor': (res.data.sysAdmin) ? 'Si' : 'No', 'formato': '' },
                        { 'nombre': 'sysUser', 'campo': 'SysUser', 'valor': (res.data.sysUser) ? 'Si' : 'No', 'formato': '' },
                    ];

                    commit('SET_USUARIO_DATOS', datos);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}