import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    run: '',
    apellido: '',
    apellido2: '',
    nombre: '',
    fechaNacimiento: '1900-01-01',
    estadoCivil: '',
    profesion: '',
    direccion: '',
    comuna: '',
    email: '',
    celular: '',
    inactivo: false,

    historiaClinica: [],
    paginaActualHC: 1,
    registrosPorPaginaHC: 20,
    totalRegistrosHC: 0,
    totalPaginasHC: 0,

    turnos: [],
    paginaActualTurnos: 1,
    registrosPorPaginaTurnos: 20,
    totalRegistrosTurnos: 0,
    totalPaginasTurnos: 0,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, pacienteId) {
        state.id = pacienteId;
        state.run = '';
        state.apellido = '';
        state.apellido2 = '';
        state.nombre = '';
        state.fechaNacimiento = '1900-01-01';
        state.estadoCivil = '';
        state.profesion = '';
        state.direccion = '';
        state.comuna = '';
        state.email = '';
        state.celular = '';
        state.inactivo = false;

        state.historiaClinica = [];
        state.turnos = [];
    },
    'SET_DATOS' (state, paciente) {
        state.run = paciente.run.trim();
        state.apellido = paciente.apellido.trim();
        state.apellido2 = paciente.apellido2.trim();
        state.nombre = paciente.nombre.trim();
        state.fechaNacimiento = moment(paciente.fechaNacimiento).format('YYYY-MM-DD') ;
        state.estadoCivil = paciente.estadoCivil.trim();
        state.profesion = paciente.profesion.trim();
        state.direccion = paciente.direccion.trim();
        state.comuna = paciente.comuna.trim();
        state.email = paciente.email.trim();
        state.celular = paciente.celular.trim();
        state.inactivo = paciente.inactivo;

    },

    'INICIALIZAR_HISTORIA_CLINICA'(state) {
        state.historiaClinica = [];
    },
    'SET_HISTORIA_CLINICA'(state, historiaClinica) {
        state.historiaClinica = historiaClinica;
    },
    'SET_PAGINATION_HISTORIA_CLINICA'(state, params) {
        state.paginaActualHC = params.paginaActual;
        state.registrosPorPaginaHC = params.registrosPorPagina;
        state.totalRegistrosHC = params.totalRegistros;
        state.totalPaginasHC = params.totalPaginas;
    },
    'INICIALIZAR_TURNOS'(state) {
        state.turnos = [];
    },
    'SET_TURNOS'(state, turnos) {
        state.turnos = turnos;
    },
    'SET_PAGINATION_TURNOS'(state, params) {
        state.paginaActualTurnos = params.paginaActual;
        state.registrosPorPaginaTurnos = params.registrosPorPagina;
        state.totalRegistrosTurnos = params.totalRegistros;
        state.totalPaginasTurnos = params.totalPaginas;
    },
};

const getters = {
    getField,

};

const actions = {
    getPaciente ( { commit }, pacienteId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', pacienteId);

            let getDatos = false;
            if (pacienteId) {
                if (pacienteId != 0) {
                    getDatos = true;
                }
            }
            if (getDatos) {
                apiAxios.get('pacientes/' + pacienteId + '/abm' ) 
                .then(res => {
                    commit('SET_DATOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    getTurnos: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_TURNOS');

            let queryString = '?pageSize=' + state.registrosPorPaginaTurnos
                + '&pageNumber=' + state.paginaActualTurnos
                + '&sortByDescending=true';
    
            apiAxios.get('turnos/paciente/' + state.id + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION_TURNOS', params);
                    }
                    commit('SET_TURNOS', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getHistoriaClinica: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_HISTORIA_CLINICA');

            let queryString = '?pageSize=' + state.registrosPorPaginaHC
                + '&pageNumber=' + state.paginaActualHC
                + '&sortByDescending=true';
    
            apiAxios.get('pacientes/' + state.id + '/historia-clinica' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION_HISTORIA_CLINICA', params);
                    }
                    commit('SET_HISTORIA_CLINICA', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}