import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    nombre: '',
    area: null,
    areaId: 0,
    areaNombre: null,
    inactivo: false,
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, servicioId) {
        state.id = servicioId;
        state.nombre = '';
        state.area = null;
        state.areaId = 0;
        state.areaNombre = null;
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_DATOS' (state, servicio) {
        state.nombre = servicio.nombre.trim();
        state.area = {
            id: servicio.areaId,
            nombre: servicio.area
        };
        state.areaId = servicio.areaId;
        state.areaNombre = servicio.area.trim();
        state.inactivo = servicio.inactivo;
        state.rowVersion = servicio.rowVersion;
    },
    'SET_AREA' (state, area) {
        state.area = area;
        if (area) {
            state.areaId = area.id? area.id : 0;
            state.areaNombre = area.nombre? area.nombre.trim() : null;
        } else {
            state.areaId = 0;
            state.areaNombre = null;
        }
    },
};

const getters = {
    getField,

};

const actions = {
    inicializar ( { commit }, servicioId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', servicioId);

            let getDatos = false;
            if (servicioId) {
                if (servicioId != 0) {
                    getDatos = true;
                }
            }
            if (getDatos) {
                apiAxios.get('servicios/' + servicioId + '/abm' ) 
                .then(res => {
                    commit('SET_DATOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    save ( { commit, state } ) {
        return new Promise((resolve, reject) => {
            let save = Boolean(true);
            let errorMessage = '';

            // if (state.id == 0) {
            //     save = Boolean(false);
            //     errorMessage = 'No hay información para registrar.\nSeleccione los medicamentos a incluir en la nota de pedido';
            // }

            if (save) {
                let servicio= {
                    nombre: state.nombre.trim(),
                    areaId: state.areaId,
                    inactivo: (/true/i).test(state.inactivo) 
                }

                let url = 'servicios';
                if (state.id != 0) {
                    url += '/' + state.id;
                    servicio.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, servicio)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
                } else {
                reject(errorMessage);
            }
        })
    },
    setArea: ({commit}, area) => {
        return new Promise((resolve, reject) => {
            commit('SET_AREA', area);
            resolve();
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}