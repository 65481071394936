import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    nombre: '',
    inactivo: false,
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, salaId) {
        state.id = salaId;
        state.nombre = '';
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_DATOS' (state, sala) {
        state.nombre = sala.nombre.trim();
        state.inactivo = sala.inactivo;
        state.rowVersion = sala.rowVersion;
    },
};

const getters = {
    getField,

};

const actions = {
    inicializar ( { commit }, salaId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', salaId);

            let getDatos = false;
            if (salaId) {
                if (salaId != 0) {
                    getDatos = true;
                }
            }
            if (getDatos) {
                apiAxios.get('salas/' + salaId + '/abm' ) 
                .then(res => {
                    commit('SET_DATOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = Boolean(true);
            let errorMessage = '';

            if (save) {
                let sala= {
                    nombre: state.nombre.trim(),
                    inactivo: (/true/i).test(state.inactivo) 
                }

                let url = 'salas';
                if (state.id != 0) {
                    url += '/' + state.id;
                    sala.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, sala)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
                    
            } else {
                reject(errorMessage);
            }
        })
    }

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}