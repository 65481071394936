import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import axios from 'axios';
import helpers from '../../mixins/helpers';
import moment from 'moment';

const state = {
    anio: new Date().getFullYear(),

    diasNoLaborables: [],

    diasNoLaborablesAPI: []
};

const mutations = {
    updateField,

    'INICIALIZAR_DIAS_NO_LABORABLES'(state, anio) {
        state.anio = anio;
        state.diasNoLaborables = [];
    },
    'SET_DIAS_NO_LABORABLES'(state, diasNoLaborables) {
        state.diasNoLaborables = diasNoLaborables;
    },

    'INICIALIZAR_DIAS_NO_LABORABLES_API'(state) {
        state.diasNoLaborablesAPI = [];
    },
    'SET_DIAS_NO_LABORABLES_API'(state, dias) {
        state.diasNoLaborablesAPI = dias.filter(dia => (/true/i).test(dia.irrenunciable) == true) 
            .map(dia => ({
                fechaNoLaborable: dia.fecha,
                observaciones: dia.nombre.toUpperCase().trim()
            }))
    },
};

const actions = {
    getDiasNoLaborables: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DIAS_NO_LABORABLES', state.anio);

            apiAxios.get('diasnolaborables/' + state.anio)
                .then(res => {
                    commit('SET_DIAS_NO_LABORABLES', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getDiasNoLaborablesAPI: ( { commit } ) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_DIAS_NO_LABORABLES_API');
            
            let url = 'https://apis.digital.gob.cl/fl/feriados/' + moment(new Date()).format('YYYY');
            
            console.log(url);

            // var axios = require("axios");

            const axiosInstance = axios.create({
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
                "Example": "Test"

              }
            });

            axiosInstance.get(url)
                .then(res => {
                    commit('SET_DIAS_NO_LABORABLES_API', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    addDia ( {}, params ) {
        return new Promise((resolve, reject) => {
            let dia= {
                fechaNoLaborable: moment(params.fechaNoLaborable).format('YYYY-MM-DD'),
                observaciones: params.observaciones.trim(),
            }

            apiAxios.post('diasnolaborables', dia)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    deleteDia ( {}, diaNoLaborableId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('diasnolaborables/' + diaNoLaborableId + '/delete')
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    importar({state}) {
        return new Promise((resolve, reject) => {
            if (state.diasNoLaborablesAPI) {
                apiAxios.post('diasnolaborables/import', state.diasNoLaborablesAPI)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject('No hay dias no laborables para importar');
            }
        })
    },
    validarDia ( { commit }, diaNoLaborable ) {
        return new Promise((resolve, reject) => {
            commit('VALIDAR_DIA', diaNoLaborable);
            resolve();
        })
    },    
};

const getters = {
    getField,

    diasNoLaborables(state) {
        return state.diasNoLaborables;
    },
    diasNoLaborablesCalendario(state) {
        return state.diasNoLaborables;
    },
    // fechaDesde(state) {
    //     return state.fechaDesde;
    // },
    // fechaHasta(state) {
    //     return state.fechaHasta;
    // },
    diaNoLaborable(state) {
        return state.diaNoLaborable;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}