export default {
    methods: {
        getSelected (list, nombre) {
            var selected = {};
            if (nombre) {
              for ( let key in list ){
                const element = list[key];
                if (element.nombre.trim() == nombre.trim() ){
                  selected = element;
                }
              }
            }
            return selected;
        },
        formattedData (item) {
          var value = item.valor;
          switch (item.formato.trim()) {
            case "fecha":
              value = this.$options.filters.fecha(item.valor);
              break;
            case "fechaHora":
              value = this.$options.filters.fechaHora(item.valor);
              break;
            case "fechaHoraEstado":
              value = this.$options.filters.fechaHoraEstado(item.valor);
              break;
            case "periodo":
              value = this.$options.filters.periodo(item.valor);
              break;
            case "cuit":
              value = this.$options.filters.cuit(item.valor);
              break;
            case "porcentaje":
              value = this.$options.filters.numero(item.valor, 2, true);
              break;
            case "moneda":
              value = this.$options.filters.moneda(item.valor);
              break;
          }
          return value;
        },
        camelize(str) {
          return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
            if (+match === 0) return " "; // or if (/\s+/.test(match)) for white spaces
              return index == 0 ? match.toUpperCase() : match.toLowerCase();
          });
        },
        addDays(date, days) {
          var result = new Date(date);
          result.setDate(result.getDate() + days);
          return result;
        },
        dateToString (fecha, formato = '') {
          /* Validamos si es fecha */
          if (Object.prototype.toString.call(fecha) === "[object Date]") {
            /* Es una fecha */
          } else {
            /* Validamos si es fecha como string */
            if (!isNaN(Date.parse(fecha))) {
              fecha = new Date(fecha);
            } else {
              fecha = new Date(1900, 0, 1);
            }
          }

          let anio = fecha.getFullYear();
          let mes = fecha.getMonth();
          let dia = fecha.getDate();
          let hora = fecha.getHours();
          let minutos = fecha.getMinutes();

          let diaSemanaNombre = '';
          switch (fecha.getDay()) {
            case 1:
              diaSemanaNombre = 'Lu'
              break;
            case 2:
              diaSemanaNombre = 'Ma'
              break;
            case 3:
              diaSemanaNombre = 'Mi'
              break;
            case 4:
              diaSemanaNombre = 'Ju'
              break;
            case 5:
              diaSemanaNombre = 'Vi'
              break;
            case 6:
              diaSemanaNombre = 'Sa'
              break;
          }
          
          switch (formato) {
            case 'DD/MM':
              return `${dia.toString().padStart(2, '0')}/${(mes + 1).toString().padStart(2, '0')}`;

            case 'DD/MM/YYYY':
              return `${dia.toString().padStart(2, '0')}/${(mes + 1).toString().padStart(2, '0')}/${anio.toString().trim()}`;

            case 'DDD DD/MM':
              return `${diaSemanaNombre} ${dia.toString().padStart(2, '0')}/${(mes + 1).toString().padStart(2, '0')}`;
            
            case 'YYYY-MM-DD HH:mm':
              return `${anio.toString().trim()}-${(mes + 1).toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')} ${hora.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`;
            
            default:
              /* YYYY-MM-DD */
              return `${anio.toString().trim()}-${(mes + 1).toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;
          }
        },
        showSpinner () {
          let height = 0;
          let offsetTop = 0;
          let width = 0;
          if (document.getElementsByClassName("app-body")) {
            if (document.getElementsByClassName("app-body")[0]) {
              height = document.getElementsByClassName("app-body")[0].clientHeight
                + document.getElementsByClassName("app-body")[0].offsetTop;
              offsetTop = document.getElementsByClassName("app-body")[0].offsetTop;
              width = document.getElementsByClassName("app-body")[0].clientWidth;
            }
          }
          let params = {
            height,
            offsetTop,
            width
          }
  
          this.$store.dispatch('spinner/show', params);
        },
        hideSpinner () {
          this.$store.dispatch('spinner/hide');
        },
        getErrorMessage(error) {
          // console.log(error);
          
          let errorMessage = '';
          if (error.response) {
            if (error.response.status == 500) {
                if (error.response.data) {
                  switch (errorMessage = error.response.data.trim().toLowerCase()) {
                    case 'unable to connect to any of the specified mysql hosts.':
                      errorMessage = 'Se produjo un error al intentar conectar con la base de datos.'
                      break;
                  
                    default:
                      errorMessage = error.response.data;
                      break;
                  }
                }
            } else if (error.response.status == 400) {
              if (error.response.data) {
                errorMessage = error.response.data;
  
                if (error.response.data.hasOwnProperty('Message')) {
                  if (error.response.data.Message == 'ModelStateInvalid') {
                    if (error.response.data.Data.validations) {
                      errorMessage = '';
  
                      for (const validationItem in error.response.data.Data.validations) {
                        let validation = error.response.data.Data.validations[validationItem];
  
                        if (validation.errors) {
                            validation.errors.forEach(error => {
                              errorMessage += '\r\n' + error.errorMessage.trim();
                            });
                        }
                      }
                    } 
                  }
                }
              } else {
                errorMessage = error.message;
              }
            } else if (error.response.status == 401) {
              if (error.response.data) {
                errorMessage = error.response.data;
              } else {
                errorMessage = error.message;
              }
            }
          }  
          if (errorMessage == '') {
            if (error.message) {
              switch (error.message.trim().toLowerCase()) {
                case 'network error':
                  errorMessage = 'Se produjo un error al intentar conectar al servidor de la aplicación.'
                  break;
              
                default:
                  errorMessage = error.message;
                  break;
              }
            }
          }
          return errorMessage;
        },
    },
};