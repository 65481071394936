import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    /* Registro */
    areas: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 20,
    totalRegistros: 0,
    totalPaginas: 0,
    /* Filtro */
    nombre: '',
    incluirInactivos: false,

    nombreBusqueda: '',

    list: [],
    selectList: []
};

const mutations = {
    updateField,

    'INICIALIZAR_AREAS'(state) {
        state.areas = [];
    },
    'SET_AREAS'(state, areas) {
        state.areas = areas;

        state.paginaActual= 1;
        state.totalRegistros = state.areas.length;
    },
    // 'SET_PAGINATION'(state, params) {
    //     state.paginaActual = params.paginaActual;
    //     state.registrosPorPagina = params.registrosPorPagina;
    //     state.totalRegistros = params.totalRegistros;
    //     state.totalPaginas = params.totalPaginas;
    // },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, list) {
        state.list = list;
        state.selectList = list.map(area => area.nombre.trim());
    },
};

const actions = {
    getAreas: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_AREAS');

            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual

            if (state.nombre != '') {
                queryString += '&nombre=' + state.nombre;
            }

            if ((/true/i).test(state.incluirInactivos)) {
                queryString += '&incluirInactivos=true';
            }

            apiAxios.get('areas' + queryString)
                .then(res => {
                    // if (res.headers.pagination){
                    //     const pagination= JSON.parse(res.headers.pagination)
                    //     var params = {
                    //         paginaActual: pagination.currentPage,
                    //         registrosPorPagina: pagination.itemsPerPage,
                    //         totalRegistros: pagination.totalItems,
                    //         totalPaginas: pagination.totalPages,
                    //     }    
                    //     commit('SET_PAGINATION', params);
                    // }
                    commit('SET_AREAS', res.data)

                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getList: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            apiAxios.get('areas/list')
                .then(res => {
                    commit('SET_LIST', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,

    list(state) {
        return state.list;
    },
    selectList(state) {
        return state.selectList;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}