import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    nombre: '',
    inactivo: false,
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, areaId) {
        state.id = areaId;
        state.nombre = '';
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_DATOS' (state, area) {
        state.nombre = area.nombre.trim();
        state.inactivo = area.inactivo;
        state.rowVersion = area.rowVersion;
    },
};

const getters = {
    getField,

};

const actions = {
    inicializar ( { commit }, areaId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', areaId);

            let getDatos = false;
            if (areaId) {
                if (areaId != 0) {
                    getDatos = true;
                }
            }
            if (getDatos) {
                apiAxios.get('areas/' + areaId + '/abm' ) 
                .then(res => {
                    commit('SET_DATOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    save ( { commit, state } ) {
        return new Promise((resolve, reject) => {
            let save = Boolean(true);
            let errorMessage = '';

            // if (state.id == 0) {
            //     save = Boolean(false);
            //     errorMessage = 'No hay información para registrar.\nSeleccione los medicamentos a incluir en la nota de pedido';
            // }

            if (save) {
                let area= {
                    nombre: state.nombre.trim(),
                    inactivo: (/true/i).test(state.inactivo) 
                }

                let url = 'areas';
                if (state.id != 0) {
                    url += '/' + state.id;
                    area.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, area)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    }

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}