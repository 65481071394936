import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import moment from 'moment';

const state = {
    id: 0,
    fechaRegistro: null,
    fechaHora: null,
    // fechaRegistro: new Date('1900-01-01T00:00'),
    // fechaHora: new Date('1900-01-01T00:00'),
    duracionTurno: 0,
    sala: '',
    servicio: '',
    tratamiento: '',
    profesional: '',
    pacienteId: 0,
    paciente: '',
    usuario: '',
    observaciones: ''
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, turnoId) {
        state.id = turnoId;
        state.fechaRegistro = null;
        state.fechaHora = null;
        // state.fechaRegistro = new Date('1900-01-01T00:00');
        // state.fechaHora = new Date(1900, 0, 1);
        // state.fechaRegistro = '1900-01-01';
        // state.fechaHora = '1900-01-01';
        state.duracionTurno = 0;
        state.sala = '';
        state.servicio = '';
        state.tratamiento = '';
        state.profesional = '';
        state.pacienteId = 0;
        state.paciente = '';
        state.usuario = '';
        state.observaciones = '';
    },
    'SET_TURNO' (state, turno) {
        state.fechaRegistro = new Date(turno.fechaRegistro);
        state.fechaHora = new Date(turno.fechaHoraTurno);
        // state.fechaRegistro = moment(turno.fechaRegistro).format('YYYY-MM-DD HH:mm');
        // state.fechaHora = moment(turno.fechaHoraTurno).format('YYYY-MM-DD HH:mm');
        state.duracionTurno = turno.duracionTurno;
        state.sala = turno.sala.trim();
        state.servicio = turno.servicio.trim();
        state.tratamiento = turno.tratamiento.trim();
        state.profesional = turno.profesional.trim();
        state.pacienteId = turno.pacienteId;
        state.paciente = turno.paciente.trim();
        state.usuario = turno.usuario.trim();
        state.observaciones = turno.observaciones.trim();
    },
    'SET_OBSERVACIONES' (state, observaciones) {
        state.observaciones = observaciones.trim();
    },
};

const getters = {
    getField,

};

const actions = {
    getTurno ( { commit }, turnoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', turnoId);

            apiAxios.get('turnos/' + turnoId ) 
            .then(res => {
                commit('SET_TURNO', res.data);
                resolve();
            })
            .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })            
    },

    setObservaciones ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            let turnoObservaciones = {
                observaciones: params.observaciones.trim()
            }

            apiAxios.post('turnos/' + params.turnoId + '/observaciones', turnoObservaciones)
                .then(() => {
                    commit('SET_OBSERVACIONES', params.observaciones.trim());
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}