import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    nombre: '',
    area: '',
    servicio: '',
    duracionTurno: 0,
    precioLista: 0,
    precioOferta: 0,
    requiereProfesional: false,
    inactivo: false,
    
    salas: [],
    salasPaginaActual: 1,
    salasRegistrosPorPagina: 20,
    salasTotalRegistros: 0,
    salasTotalPaginas: 0,

    profesionales: [],
    profesionalesPaginaActual: 1,
    profesionalesRegistrosPorPagina: 20,
    profesionalesTotalRegistros: 0,
    profesionalesTotalPaginas: 0,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, tratamientoId) {
        state.id = tratamientoId;
        state.nombre = '';
        state.area = '';
        state.servicio = '';
        state.duracionTurno = 0;
        state.precioLista = 0;
        state.precioOferta = 0;
        state.requiereProfesional = false;
        state.inactivo = false;

        state.salas = [];
        state.salasPaginaActual = 1;
        state.salasTotalRegistros = 0;
        state.salasTotalPaginas = 0;
    
        state.profesionales = [];
        state.profesionalesPaginaActual = 1;
        state.profesionalesTotalRegistros = 0;
        state.profesionalesTotalPaginas = 0;
    },
    'SET_DATOS' (state, tratamiento) {
        state.nombre = tratamiento.nombre.trim();
        state.area = tratamiento.area.trim();
        state.servicio = tratamiento.servicio.trim();
        state.duracionTurno = parseInt(tratamiento.duracionTurno);
        state.precioLista = parseFloat(tratamiento.precioLista);
        state.precioOferta = parseFloat(tratamiento.precioOferta);
        state.requiereProfesional = (/true/i).test(tratamiento.requiereProfesional);
        state.inactivo = (/true/i).test(tratamiento.inactivo);
        
        state.salas = tratamiento.salas;
        if (state.salas) {
            state.salasTotalRegistros = state.salas.length;
        }

        state.profesionales = tratamiento.profesionales;
        if (state.profesionales) {
            state.profesionalesTotalRegistros = state.profesionales.length;
        }
    },
    'ADD_SALA' (state, sala) {
        if (state.salas) {
            let itemKey = state.salas
                .findIndex(s => s.id == sala.id);

            if (itemKey == -1) {
                state.salas.push(sala);
                state.salasTotalRegistros = state.salas.length;
            }
        }
    },
    'REMOVE_SALA' (state, salaId) {
        if (state.salas) {
            let itemKey = state.salas
                .findIndex(sala => sala.id == salaId);

            if (itemKey != -1) {
                state.salas.splice(itemKey, 1);
                state.salasTotalRegistros = state.salas.length;
            }
        }
    },
    'ADD_PROFESIONAL' (state, profesional) {
        if (state.profesionales) {
            let itemKey = state.profesionales
                .findIndex(s => s.id == profesional.id);

            if (itemKey == -1) {
                state.profesionales.push(profesional);
                state.profesionalesTotalRegistros = state.profesionales.length;
            }
        }
    },
    'REMOVE_PROFESIONAL' (state, profesionalId) {
        if (state.profesionales) {
            let itemKey = state.profesionales
                .findIndex(t => t.id == profesionalId);

            if (itemKey != -1) {
                state.profesionales.splice(itemKey, 1);
                state.profesionalesTotalRegistros = state.profesionales.length;
            }
        }
    }
};

const getters = {
    getField,

};

const actions = {
    inicializar ( { commit }, tratamientoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', tratamientoId);

            let getDatos = false;
            if (tratamientoId) {
                if (tratamientoId != 0) {
                    getDatos = true;
                }
            }
            if (getDatos) {
                apiAxios.get('tratamientos/' + tratamientoId ) 
                .then(res => {
                    commit('SET_DATOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    addSala ( { state, commit }, sala ) {
        return new Promise((resolve, reject) => {
            let add = false;
            if (sala) {
                if (sala.id != 0) {
                    add = true;
                }
            }
            if (add) {
                apiAxios.post('tratamientos/' + state.id + '/salas/' + sala.id)
                    .then(() => {
                        commit('ADD_SALA', sala);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    removeSala ( { commit }, salaId ) {
        return new Promise((resolve, reject) => {
            let remove = false;
            if (salaId) {
                if (salaId != 0) {
                    remove = true;
                }
            }
            if (remove) {
                apiAxios.post('tratamientos/' + state.id + '/salas/' + salaId + '/delete')
                    .then(() => {
                        commit('REMOVE_SALA', salaId);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    addProfesional ( { state, commit }, profesional ) {
        return new Promise((resolve, reject) => {
            let add = false;
            if (profesional) {
                if (profesional.id != 0) {
                    add = true;
                }
            }
            if (add) {
                apiAxios.post('tratamientos/' + state.id + '/profesionales/' + profesional.id)
                    .then(() => {
                        commit('ADD_PROFESIONAL', profesional);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    removeProfesional ( { commit }, profesionalId ) {
        return new Promise((resolve, reject) => {
            let remove = false;
            if (profesionalId) {
                if (profesionalId != 0) {
                    remove = true;
                }
            }
            if (remove) {
                apiAxios.post('tratamientos/' + state.id + '/profesionales/' + profesionalId + '/delete')
                    .then(() => {
                        commit('REMOVE_PROFESIONAL', profesionalId);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}