import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    turnoId: 0,
    pacienteId: 0,
    paciente: '',
    fechaNacimiento: new Date(1900, 0, 1),
    fechaConsulta: new Date(),
    fechaUltimaConsulta: new Date(1900, 0, 1),
    mediciones: [],

    zonasTratamiento: '',
    antecedentesCardiacos: false,
    enfermedadesInmunologicas: false,
    embarazo: false,
    enfermedadesCutaneas: false,
    diabetes: false,
    cancer: false,
    queloides: false,
    alergias: false,
    alergiasDescripcion: '',
    tratamientosEsteticosActuales: '',
    tratamientosEsteticosAnteriores: '',
    enfermedadesActuales: '',

    motivoConsulta: '',
    examenFisico: '',
    diagnostico: '',
    tratamientoRealizado: '',
    indicaciones: '',

    medicionPeso: 0,
    medicionTalla: 0,

    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, params) {
        state.id = params.historiaClinicaEntradaId;
        state.turnoId = params.turnoId;
        state.pacienteId = params.pacienteId;
        state.paciente = '';
        state.fechaNacimiento = new Date(1900, 0, 1);
        state.fechaConsulta = new Date();
        state.fechaUltimaConsulta = new Date(1900, 0, 1);

        state.mediciones = [];

        state.zonasTratamiento = '';
        state.antecedentesCardiacos = false;
        state.enfermedadesInmunologicas = false;
        state.embarazo = false;
        state.enfermedadesCutaneas = false;
        state.diabetes = false;
        state.cancer = false;
        state.queloides = false;
        state.alergias = false;
        state.alergiasDescripcion = '';
        state.tratamientosEsteticosActuales = '';
        state.tratamientosEsteticosAnteriores = '';
        state.enfermedadesActuales = '';

        state.motivoConsulta = '';
        state.examenFisico = '';
        state.diagnostico = '';
        state.tratamientoRealizado = '';
        state.indicaciones = '';

        state.medicionPeso = 0;
        state.medicionTalla = 0;
            
        state.rowVersion = null;

        // console.log(state.fechaConsulta);
    },
    'SET_HISTORIA_CLINICA_ENTRADA' (state, historiaClinicaEntrada) {
        state.turnoId = historiaClinicaEntrada.turnoId;
        state.pacienteId = historiaClinicaEntrada.pacienteId;
        state.paciente = historiaClinicaEntrada.paciente.trim();

        state.fechaNacimiento = new Date(historiaClinicaEntrada.fechaNacimiento);
        state.fechaConsulta = new Date(historiaClinicaEntrada.fechaConsulta);
        state.fechaUltimaConsulta = new Date(historiaClinicaEntrada.fechaUltimaConsulta);
        // state.fechaNacimiento = moment(historiaClinicaEntrada.fechaNacimiento).format('YYYY-MM-DD') ;
        // state.fechaConsulta = moment(historiaClinicaEntrada.fechaConsulta).format('YYYY-MM-DD HH:mm') ;
        // state.fechaUltimaConsulta = moment(historiaClinicaEntrada.fechaUltimaConsulta).format('YYYY-MM-DD HH:mm') ;

        state.mediciones = historiaClinicaEntrada.mediciones;

        state.zonasTratamiento = historiaClinicaEntrada.zonasTratamiento.trim();
        state.antecedentesCardiacos = (/true/i).test(historiaClinicaEntrada.antecedentesCardiacos);
        state.enfermedadesInmunologicas = (/true/i).test(historiaClinicaEntrada.enfermedadesInmunologicas);
        state.embarazo = (/true/i).test(historiaClinicaEntrada.embarazo);
        state.enfermedadesCutaneas = (/true/i).test(historiaClinicaEntrada.enfermedadesCutaneas);
        state.diabetes = (/true/i).test(historiaClinicaEntrada.diabetes);
        state.cancer = (/true/i).test(historiaClinicaEntrada.cancer);
        state.queloides = (/true/i).test(historiaClinicaEntrada.queloides);
        state.alergias = (/true/i).test(historiaClinicaEntrada.alergias);
        state.alergiasDescripcion = historiaClinicaEntrada.alergiasDescripcion.trim();
        state.tratamientosEsteticosActuales = historiaClinicaEntrada.tratamientosEsteticosActuales.trim();
        state.tratamientosEsteticosAnteriores = historiaClinicaEntrada.tratamientosEsteticosAnteriores.trim();
        state.enfermedadesActuales = historiaClinicaEntrada.enfermedadesActuales.trim();

        state.motivoConsulta = historiaClinicaEntrada.motivoConsulta.trim();
        state.examenFisico = historiaClinicaEntrada.examenFisico.trim();
        state.diagnostico = historiaClinicaEntrada.diagnostico.trim();
        state.tratamientoRealizado = historiaClinicaEntrada.tratamientoRealizado.trim();
        state.indicaciones = historiaClinicaEntrada.indicaciones.trim();

        state.medicionPeso = parseFloat(historiaClinicaEntrada.medicionPeso);
        state.medicionTalla = parseFloat(historiaClinicaEntrada.medicionTalla);

        state.rowVersion = historiaClinicaEntrada.rowVersion;

        // console.log(state.fechaConsulta);
    },
};

const getters = {
    getField,

};

const actions = {
    inicializar ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', params);

            let getDatos = false;
            let getPaciente = false;

            if (params.historiaClinicaEntradaId) {
                if (params.historiaClinicaEntradaId != 0) {
                    getDatos = true;
                }
            }
            if (params.pacienteId) {
                if (params.pacienteId != 0) {
                    getPaciente = true;
                }
            }

            if (getPaciente) {
                let url = '';
                
                if (getDatos) {
                    url= 'pacientes/' + params.pacienteId + '/historia-clinica/'  + params.historiaClinicaEntradaId + '/abm';
                } else {
                    url= 'pacientes/' + params.pacienteId + '/historia-clinica/abm';
                }

                apiAxios.get(url) 
                .then(res => {
                    commit('SET_HISTORIA_CLINICA_ENTRADA', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = Boolean(true);
            let errorMessage = '';

            // console.log(isNaN(state.medicionPeso), state.medicionPeso);

            // if (isNaN(state.medicionPeso)) {
            //     save = false;
            //     errorMessage = 'El peso ingresado no es válido.';
            // }
            // if (isNaN(state.medicionTalla)) {
            //     save = false;
            //     errorMessage = 'La talla ingresada no es válida.';
            // }

            // console.log(state.fechaConsulta);

            if (save) {
                let alergiasDescripcion = state.alergiasDescripcion.trim();
                if (!(/true/i).test(state.alergias)) {
                    alergiasDescripcion = '';
                }

                let historiaClinicaEntrada= {
                    turnoId: parseInt(state.turnoId),
                    pacienteId: parseInt(state.pacienteId),
                    fechaConsulta: moment(state.fechaConsulta).format('YYYY-MM-DD HH:mm'),

                    zonasTratamiento: state.zonasTratamiento.trim(),
                    antecedentesCardiacos: (/true/i).test(state.antecedentesCardiacos),
                    enfermedadesInmunologicas: (/true/i).test(state.enfermedadesInmunologicas),
                    embarazo: (/true/i).test(state.embarazo),
                    enfermedadesCutaneas: (/true/i).test(state.enfermedadesCutaneas),
                    diabetes: (/true/i).test(state.diabetes),
                    cancer: (/true/i).test(state.cancer),
                    queloides: (/true/i).test(state.queloides),
                    alergias: (/true/i).test(state.alergias),
                    alergiasDescripcion: alergiasDescripcion.trim(),
                    tratamientosEsteticosActuales: state.tratamientosEsteticosActuales.trim(),
                    tratamientosEsteticosAnteriores: state.tratamientosEsteticosAnteriores.trim(),
                    enfermedadesActuales: state.enfermedadesActuales.trim(),

                    motivoConsulta: state.motivoConsulta.trim(),
                    examenFisico: state.examenFisico.trim(),
                    diagnostico: state.diagnostico.trim(),
                    tratamientoRealizado: state.tratamientoRealizado.trim(),
                    indicaciones: state.indicaciones.trim(),

                    medicionPeso: state.medicionPeso,
                    medicionTalla: state.medicionTalla,
                }

                let url = 'pacientes/' + state.pacienteId + '/historia-clinica' ;
                if (state.id != 0) {
                    url += '/' + state.id;
                    historiaClinicaEntrada.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, historiaClinicaEntrada)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}