import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    horarioPublico: false, 

    lunesHoraInicio: '',
    lunesHoraFin: '',
    martesHoraInicio: '',
    martesHoraFin: '',
    miercolesHoraInicio: '',
    miercolesHoraFin: '',
    juevesHoraInicio: '',
    juevesHoraFin: '',
    viernesHoraInicio: '',
    viernesHoraFin: '',
    sabadoHoraInicio: '',
    sabadoHoraFin: '',
    rowVersion: null
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, horarioPublico) {
        state.horarioPublico = (/true/i).test(horarioPublico);
        state.lunesHoraInicio = '00:00';
        state.lunesHoraFin = '00:00';
        state.martesHoraInicio = '00:00';
        state.martesHoraFin = '00:00';
        state.miercolesHoraInicio = '00:00';
        state.miercolesHoraFin = '00:00';
        state.juevesHoraInicio = '00:00';
        state.juevesHoraFin = '00:00';
        state.viernesHoraInicio = '00:00';
        state.viernesHoraFin = '00:00';
        state.sabadoHoraInicio = '00:00';
        state.sabadoHoraFin = '00:00';
        state.rowVersion = null;
    },
    'SET_HORARIO' (state, horario) {
        state.lunesHoraInicio = horario.lunesHoraInicio.substring(0, 5);
        state.lunesHoraFin = horario.lunesHoraFin.substring(0, 5);
        state.martesHoraInicio = horario.martesHoraInicio.substring(0, 5);
        state.martesHoraFin = horario.martesHoraFin.substring(0, 5);
        state.miercolesHoraInicio = horario.miercolesHoraInicio.substring(0, 5);
        state.miercolesHoraFin = horario.miercolesHoraFin.substring(0, 5);
        state.juevesHoraInicio = horario.juevesHoraInicio.substring(0, 5);
        state.juevesHoraFin = horario.juevesHoraFin.substring(0, 5);
        state.viernesHoraInicio = horario.viernesHoraInicio.substring(0, 5);
        state.viernesHoraFin = horario.viernesHoraFin.substring(0, 5);
        state.sabadoHoraInicio = horario.sabadoHoraInicio.substring(0, 5);
        state.sabadoHoraFin = horario.sabadoHoraFin.substring(0, 5);
        state.rowVersion = horario.rowVersion;
    },
    'SET_VERSION' (state, horarioVersion) {
        console.log(state.rowVersion, horarioVersion.rowVersion)
        state.rowVersion = horarioVersion.rowVersion;
    }
};

const getters = {
    getField,

};

const actions = {
    getHorario ( { commit }, horarioPublico ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', horarioPublico);
            
            let url = 'horarios';
            if ((/true/i).test(horarioPublico)) {
                url += '/publico';
            }

            apiAxios.get(url)
                .then(res => {
                    commit('SET_HORARIO', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })            
    },
    save ( { commit, state } ) {
        return new Promise((resolve, reject) => {
            let save = Boolean(true);
            let errorMessage = '';
            
            /* Validaciones */

            if (save) {
                let horario= {
                    lunesHoraInicio: state.lunesHoraInicio,
                    lunesHoraFin: state.lunesHoraFin,
                    martesHoraInicio: state.martesHoraInicio,
                    martesHoraFin: state.martesHoraFin,
                    miercolesHoraInicio: state.miercolesHoraInicio,
                    miercolesHoraFin: state.miercolesHoraFin,
                    juevesHoraInicio: state.juevesHoraInicio,
                    juevesHoraFin: state.juevesHoraFin,
                    viernesHoraInicio: state.viernesHoraInicio,
                    viernesHoraFin: state.viernesHoraFin,
                    sabadoHoraInicio: state.sabadoHoraInicio,
                    sabadoHoraFin: state.sabadoHoraFin,
                    rowVersion: state.rowVersion                    
                }

                let url = 'horarios';
                if ((/true/i).test(state.horarioPublico)) {
                    url += '/publico';
                }
    
                apiAxios.post(url, horario)
                    .then(res => {
                        commit('SET_VERSION', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    }

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}