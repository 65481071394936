import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    apellido: '',
    apellido2: '',
    nombre: '',
    email: '',
    celular: '',
    inactivo: false,
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, profesionalId) {
        state.id = profesionalId;
        state.apellido = '';
        state.apellido2 = '';
        state.nombre = '';
        state.email = '';
        state.celular = '';
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_DATOS' (state, profesional) {
        state.apellido = profesional.apellido.trim();
        state.apellido2 = profesional.apellido2.trim();
        state.nombre = profesional.nombre.trim();
        state.email = profesional.email.trim();
        state.celular = profesional.celular.trim();
        state.inactivo = (/true/i).test(profesional.inactivo);
        state.rowVersion = profesional.rowVersion;
    },
};

const getters = {
    getField,

};

const actions = {
    inicializar ( { commit }, profesionalId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', profesionalId);

            let getDatos = false;
            if (profesionalId) {
                if (profesionalId != 0) {
                    getDatos = true;
                }
            }
            if (getDatos) {
                apiAxios.get('profesionales/' + profesionalId + '/abm' ) 
                .then(res => {
                    commit('SET_DATOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    save ( { commit, state } ) {
        return new Promise((resolve, reject) => {
            let save = Boolean(true);
            let errorMessage = '';

            // if (state.id == 0) {
            //     save = Boolean(false);
            //     errorMessage = 'No hay información para registrar.\nSeleccione los medicamentos a incluir en la nota de pedido';
            // }

            if (save) {
                let profesional= {
                    apellido: state.apellido.trim(),
                    apellido2: state.apellido2.trim(),
                    nombre: state.nombre.trim(),
                    email: state.email.trim(),
                    celular: state.celular.trim(),
                    inactivo: (/true/i).test(state.inactivo) 
                }

                let url = 'profesionales';
                if (state.id != 0) {
                    url += '/' + state.id;
                    profesional.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, profesional)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}