import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    /* Registro */
    servicios: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 20,
    totalRegistros: 0,
    totalPaginas: 0,
    /* Filtro */
    nombre: '',
    area: {
        id: 0,
        nombre: null
    },
    incluirInactivos: false,

    nombreBusqueda: '',

    list: [],
    selectList: []
};

const mutations = {
    updateField,

    'INICIALIZAR_SERVICIOS'(state) {
        state.servicios = [];
    },
    'SET_SERVICIOS'(state, servicios) {
        state.servicios = servicios;

        state.paginaActual= 1;
        state.totalRegistros = state.servicios.length;
    },
    // 'SET_PAGINATION'(state, params) {
    //     state.paginaActual = params.paginaActual;
    //     state.registrosPorPagina = params.registrosPorPagina;
    //     state.totalRegistros = params.totalRegistros;
    //     state.totalPaginas = params.totalPaginas;
    // },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, list) {
        state.list = list;
        state.selectList = list.map(servicio => servicio.nombre.trim());
    },
};

const actions = {
    getServicios: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_SERVICIOS');

            var queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual

            if (state.nombre != '') {
                queryString += '&nombre=' + state.nombre;
            }

            if (state.area) {
                if (state.area.id) {
                    if (state.area.id != 0) {
                        queryString += '&areaId=' + state.area.id;
                    }
                }
            }

            if ((/true/i).test(state.incluirInactivos)) {
                queryString += '&incluirInactivos=true';
            }

            apiAxios.get('servicios' + queryString)
                .then(res => {
                    commit('SET_SERVICIOS', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getList: ({commit}, areaId) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            let queryString = '';
            if (areaId) {
                if (areaId != 0) {
                    queryString += '?areaId=' + areaId;
                }
            }

            apiAxios.get('servicios/list' + queryString)
                .then(res => {
                    commit('SET_LIST', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getListBySala: ( { commit }, salaId) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            let queryString = '';
            if (salaId) {
                if (salaId != 0) {
                    queryString += '?salaId=' + salaId;
                }
            }

            apiAxios.get('servicios/list' + queryString)
                .then(res => {
                    commit('SET_LIST', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getListForTurno: ( { commit }, params) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            let queryString = '';

            if (params) {
                if (params.salaId) {
                    if (params.salaId != 0) {
                        queryString += '?salaId=' + params.salaId;
                    }
                }
                if (params.profesionalId) {
                    if (params.profesionalId != 0) {
                        queryString += '?profesionalId=' + params.profesionalId;
                    }
                }
            }

            apiAxios.get('servicios/list' + queryString)
                .then(res => {
                    commit('SET_LIST', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,

    list(state) {
        return state.list;
    },
    selectList(state) {
        return state.selectList;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}