import Vue from 'vue';
import Vuex from 'vuex';

import agenda from './modules/agenda';
import agendaProfesional from './modules/agendaProfesional';
import agendaSala from './modules/agendaSala';
import agendaSalas from './modules/agendaSalas';
import area from './modules/area';
import areas from './modules/areas';
import comunas from './modules/comunas';
import diasNoLaborables from './modules/diasNoLaborables';
import historiaClinicaEntrada from './modules/historiaClinicaEntrada';
import historiaClinicaEntradaDatos from './modules/historiaClinicaEntradaDatos';
import horario from './modules/horario';
import paciente from './modules/paciente';
import pacienteDatos from './modules/pacienteDatos';
import pacientes from './modules/pacientes';
import profesional from './modules/profesional';
import profesionalDatos from './modules/profesionalDatos';
import profesionales from './modules/profesionales';
import sala from './modules/sala';
import salas from './modules/salas';
import servicio from './modules/servicio';
import servicios from './modules/servicios';
import tratamiento from './modules/tratamiento';
import tratamientoDatos from './modules/tratamientoDatos';
import tratamientos from './modules/tratamientos';
import turno from './modules/turno';
import turnoDatos from './modules/turnoDatos';
import turnos from './modules/turnos';

import spinner from './modules/spinner';
import user from './modules/user';
import usuario from './modules/usuario';
import usuario_perfil from './modules/usuario_perfil';
import usuarios from './modules/usuarios';
import usuariosNoHabilitados from './modules/usuariosNoHabilitados';
import usuariosRoles from './modules/usuariosRoles';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        agenda,
        agendaProfesional,
        agendaSala,
        agendaSalas,
        area,
        areas,
        comunas,
        diasNoLaborables,
        historiaClinicaEntrada,
        historiaClinicaEntradaDatos,
        horario,
        paciente,
        pacienteDatos,
        pacientes,
        profesional,
        profesionalDatos,
        profesionales,
        sala,
        salas,
        servicio,
        servicios,
        tratamiento,
        tratamientoDatos,
        tratamientos,
        turno,
        turnoDatos,
        turnos,
        
        spinner,
        user,
        usuario,
        usuario_perfil,
        usuarios,
        usuariosNoHabilitados,
        usuariosRoles
    }
});