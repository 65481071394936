import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    salaId: 0,
    salaNombre: null,
    vistaDiaria: false,

    fechaDesde: new Date(1900, 0, 1),
    fechaHasta: new Date(1900, 0, 1),
    // fechaDesde: null,
    // fechaHasta: null,
    // fechaDesde: moment.utc(addDays(new Date(), (-1 * (new Date().getDay() - 1)) )).format('YYYY-MM-DD'),
    // fechaHasta: moment.utc(addDays(new Date(), (6 - (new Date().getDay())) )).format('YYYY-MM-DD'),

    turnos: [],
    turnosDisplay: [],
    diasNoLaborables: [],
    horario: {},

    lunesNoLaborable: false,
    martesNoLaborable: false,
    miercolesNoLaborable: false,
    juevesNoLaborable: false,
    viernesNoLaborable: false,
    sabadoNoLaborable: false
};

const mutations = {
    updateField,

    'INICIALIZAR_AGENDA' (state) {
        let fechaDesde = helpers.methods.addDays(new Date(), (-1 * (new Date().getDay() - 1)));
        let anioDesde = fechaDesde.getFullYear();
        let mesDesde = fechaDesde.getMonth();
        let diaDesde = fechaDesde.getDate();
        state.fechaDesde = new Date(anioDesde, mesDesde, diaDesde);
        
        let fechaHasta = helpers.methods.addDays(new Date(), (6 - (new Date().getDay())));
        let anioHasta = fechaHasta.getFullYear();
        let mesHasta = fechaHasta.getMonth();
        let diaHasta = fechaHasta.getDate();
        state.fechaHasta = new Date(anioHasta, mesHasta, diaHasta);

        state.turnos = [];
        state.turnosDisplay = [];
        state.horario = {}; 
        state.diasNoLaborables = [];
        state.vistaDiaria = false;

        state.lunesNoLaborable = false;
        state.martesNoLaborable = false;
        state.miercolesNoLaborable = false;
        state.juevesNoLaborable = false;
        state.viernesNoLaborable = false;
        state.sabadoNoLaborable = false;
    },
    'INICIALIZAR_TURNOS' (state) {
        state.turnos = [];
        state.turnosDisplay = [];
        state.horario = {}; 
        state.diasNoLaborables = [];
        // state.vistaDiaria = false;

        state.lunesNoLaborable = false;
        state.martesNoLaborable = false;
        state.miercolesNoLaborable = false;
        state.juevesNoLaborable = false;
        state.viernesNoLaborable = false;
        state.sabadoNoLaborable = false;
    },
    'SET_TURNOS' (state, agenda) {
        state.turnos = agenda.turnos;
        state.horario = agenda.horario;
        state.diasNoLaborables = agenda.diasNoLaborables;
        
        if ((/true/i).test(state.vistaDiaria)) {
            getTurnosFecha(state);
        } else {
            getTurnosPeriodo(state);
        }
    },
    'SET_AGENDA_CURRENT' (state) {
        if ((/true/i).test(state.vistaDiaria)) {
            let fecha = new Date();
            let anio = fecha.getFullYear();
            let mes = fecha.getMonth();
            let dia = fecha.getDate();

            state.fechaDesde = new Date(anio, mes, dia);
            state.fechaHasta = new Date(anio, mes, dia);

            // state.fechaDesde = moment(new Date()).format('YYYY-MM-DD');
            // state.fechaHasta = moment(new Date()).format('YYYY-MM-DD');
        } else {
            let fechaDesde = helpers.methods.addDays(new Date(), (-1 * (new Date().getDay() - 1)));
            let anioDesde = fechaDesde.getFullYear();
            let mesDesde = fechaDesde.getMonth();
            let diaDesde = fechaDesde.getDate();
            state.fechaDesde = new Date(anioDesde, mesDesde, diaDesde);
            
            let fechaHasta = helpers.methods.addDays(new Date(), (6 - (new Date().getDay())));
            let anioHasta = fechaHasta.getFullYear();
            let mesHasta = fechaHasta.getMonth();
            let diaHasta = fechaHasta.getDate();
            state.fechaHasta = new Date(anioHasta, mesHasta, diaHasta);

            // state.fechaDesde = moment(addDays(new Date(), (-1 * (new Date().getDay() - 1)) )).format('YYYY-MM-DD');
            // state.fechaHasta = moment(addDays(new Date(), (6 - (new Date().getDay())) )).format('YYYY-MM-DD');
        }
    },
    'SET_AGENDA_DIARIA' (state, fecha) {
        // console.log('SET_AGENDA_DIARIA');
        state.turnos = [];
        state.turnosDisplay = [];
        state.horario = {}; 
        state.diasNoLaborables = [];

        state.fechaDesde = fecha;
        state.fechaHasta = fecha;
        // state.fechaDesde = moment(fecha).format('YYYY-MM-DD');
        // state.fechaHasta = moment(fecha).format('YYYY-MM-DD');
        state.vistaDiaria = true;
    },
    'SET_AGENDA_SEMANAL' (state) {
        state.turnos = [];
        state.turnosDisplay = [];
        state.horario = {}; 
        state.diasNoLaborables = [];

        // let fecha = state.fechaDesde;

        // state.fechaDesde = moment(addDays(new Date(fecha), (-1 * (new Date(fecha).getDay() - 1)) )).format('YYYY-MM-DD');
        // state.fechaHasta = moment(addDays(new Date(fecha), (6 - (new Date(fecha).getDay())) )).format('YYYY-MM-DD');

        let anioInicial = state.fechaDesde.getFullYear();
        let mesInicial = state.fechaDesde.getMonth();
        let diaInicial = state.fechaDesde.getDate();
        let fechaInicial = new Date(anioInicial, mesInicial, diaInicial);

        let fechaDesde = helpers.methods.addDays(fechaInicial, (-1 * (fechaInicial.getDay() - 1)));
        let anioDesde = fechaDesde.getFullYear();
        let mesDesde = fechaDesde.getMonth();
        let diaDesde = fechaDesde.getDate();
        state.fechaDesde = new Date(anioDesde, mesDesde, diaDesde);
        
        let fechaHasta = helpers.methods.addDays(fechaInicial, (6 - (fechaInicial.getDay())));
        let anioHasta = fechaHasta.getFullYear();
        let mesHasta = fechaHasta.getMonth();
        let diaHasta = fechaHasta.getDate();
        state.fechaHasta = new Date(anioHasta, mesHasta, diaHasta);

        state.vistaDiaria = false;
    },
    'SET_PERIODO' (state, previa) {
        if ((/true/i).test(state.vistaDiaria)) {
            /* Vista Diaria */
            if ((/true/i).test(previa)) {
                let proximaFecha = helpers.methods.addDays(state.fechaDesde, -1);

                if (proximaFecha.getDay() == 0) {
                    proximaFecha = helpers.methods.addDays(state.fechaDesde, -2);
                }
                state.fechaDesde = proximaFecha;
                state.fechaHasta = proximaFecha;

                // state.fechaDesde = moment.utc(addDays(state.fechaDesde, -1)).format('YYYY-MM-DD');
                // state.fechaHasta = moment.utc(addDays(state.fechaHasta, -1)).format('YYYY-MM-DD');
            } else {
                let proximaFecha = helpers.methods.addDays(state.fechaDesde, 1);

                if (proximaFecha.getDay() == 0) {
                    proximaFecha = helpers.methods.addDays(state.fechaDesde, 2);
                }
                state.fechaDesde = proximaFecha;
                state.fechaHasta = proximaFecha;

                // state.fechaDesde = moment.utc(addDays(state.fechaDesde, 1)).format('YYYY-MM-DD');
                // state.fechaHasta = moment.utc(addDays(state.fechaHasta, 1)).format('YYYY-MM-DD');
            }
        } else {
            /* Vista Semanal */
            if ((/true/i).test(previa)) {
                state.fechaDesde = helpers.methods.addDays(state.fechaDesde, -7);
                state.fechaHasta = helpers.methods.addDays(state.fechaHasta, -7);
                // state.fechaDesde = moment.utc(addDays(state.fechaDesde, -7)).format('YYYY-MM-DD');
                // state.fechaHasta = moment.utc(addDays(state.fechaHasta, -7)).format('YYYY-MM-DD');
            } else {
                state.fechaDesde = helpers.methods.addDays(state.fechaDesde, 7);
                state.fechaHasta = helpers.methods.addDays(state.fechaHasta, 7);
                // state.fechaDesde = moment.utc(addDays(state.fechaDesde, 7)).format('YYYY-MM-DD');
                // state.fechaHasta = moment.utc(addDays(state.fechaHasta, 7)).format('YYYY-MM-DD');
            }
        }

    },
    'SET_SALA' (state, sala) {
        let actualizar = true;

        if (sala) {
            if (sala.id) {
                if (state.salaId == sala.id) {
                    actualizar = false;
                }
            }
        }

        if (actualizar) {
            if (sala) {
                state.salaId = sala.id? sala.id : 0;
                state.salaNombre = sala.nombre? sala.nombre.trim() : null;
            } else {
                state.salaId = 0;
                state.salaNombre = null;
            }
            state.turnos = [];
            state.turnosDisplay = [];
        }
    },
};

/* Turnos - Vista Semanal */
function getTurnosPeriodo (state) {
    state.turnosDisplay = [];

    let lunes = state.fechaDesde;
    let martes = helpers.methods.addDays(state.fechaDesde, 1);
    let miercoles = helpers.methods.addDays(state.fechaDesde, 2);
    let jueves = helpers.methods.addDays(state.fechaDesde, 3);
    let viernes = helpers.methods.addDays(state.fechaDesde, 4);
    let sabado = helpers.methods.addDays(state.fechaDesde, 5);

    /* Validamos los dias no laborables */
    state.lunesNoLaborable = !diaHabilitado(state, lunes);
    state.martesNoLaborable = !diaHabilitado(state, martes);
    state.miercolesNoLaborable = !diaHabilitado(state, miercoles);
    state.juevesNoLaborable = !diaHabilitado(state, jueves);
    state.viernesNoLaborable = !diaHabilitado(state, viernes);
    state.sabadoNoLaborable = !diaHabilitado(state, sabado);

    /* Buscamos la hora inicial en el horario habilitado */
    let fechaHoraInicio = state.horario.lunesHoraInicio;
    let fechaHoraFin = state.horario.lunesHoraFin;

    if (moment(fechaHoraInicio).isAfter(state.horario.martesHoraInicio)) {
        fechaHoraInicio = state.horario.martesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.miercolesHoraInicio)) {
        fechaHoraInicio = state.horario.miercolesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.juevesHoraInicio)) {
        fechaHoraInicio = state.horario.juevesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.viernesHoraInicio)) {
        fechaHoraInicio = state.horario.viernesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.sabadoHoraInicio)) {
        fechaHoraInicio = state.horario.sabadoHoraInicio;
    }

    if (moment(fechaHoraFin).isBefore(state.horario.martesHoraFin)) {
        fechaHoraFin = state.horario.martesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.miercolesHoraFin)) {
        fechaHoraFin = state.horario.miercolesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.juevesHoraFin)) {
        fechaHoraFin = state.horario.juevesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.viernesHoraFin)) {
        fechaHoraFin = state.horario.viernesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.sabadoHoraFin)) {
        fechaHoraFin = state.horario.sabadoHoraFin;
    }

    let diferenciaMinutos = parseInt(moment(fechaHoraFin).diff(fechaHoraInicio, 'minutes'));
    let cantidadTurnos = parseInt(diferenciaMinutos / 30);

    let inicioHora = parseInt(moment(fechaHoraInicio).format('HH'));
    let inicioMinutos = parseInt(moment(fechaHoraInicio).format('mm'));

    // let fecha = moment.utc(new Date('1900-01-01'))
    //     .add(inicioHora,'hours')
    //     .add(inicioMinutos, 'minutes');
    
    let fecha = new Date(1900, 0, 1, parseInt(inicioHora), parseInt(inicioMinutos));

    for (let index = 0; index < cantidadTurnos; index++) {
        state.turnosDisplay.push({
            hora: moment(fecha).add(index * 30,'minutes'),
            horaDisplay: moment(fecha).add(index * 30,'minutes').format('HH:mm'),
            
            lunesTurnoId: 0,
            lunesPaciente: '',
            lunesTratamiento: '',
            // lunesProfesionalId: 0,
            lunesDisponible: false,
            lunesTurnoAnterior: false,
            lunesHabilitado: turnoHabilitado(fecha, index, 
                state.horario.lunesHoraInicio, state.horario.lunesHoraFin),
            
            martesTurnoId: 0,
            martesPaciente: '',
            martesTratamiento: '',
            // martesProfesionalId: 0,
            martesDisponible: false,
            martesTurnoAnterior: false,
            martesHabilitado: turnoHabilitado(fecha, index, 
                state.horario.martesHoraInicio, state.horario.martesHoraFin),
            
            miercolesTurnoId: 0,
            miercolesPaciente: '',
            miercolesTratamiento: '',
            // miercolesProfesionalId: 0,
            miercolesDisponible: false,
            miercolesTurnoAnterior: false,
            miercolesHabilitado: turnoHabilitado(fecha, index, 
                state.horario.miercolesHoraInicio, state.horario.miercolesHoraFin),
            
            juevesTurnoId: 0,
            juevesPaciente: '',
            juevesTratamiento: '',
            // juevesProfesionalId: 0,
            juevesDisponible: false,
            juevesTurnoAnterior: false,
            juevesHabilitado: turnoHabilitado(fecha, index, 
                state.horario.juevesHoraInicio, state.horario.juevesHoraFin),
            
            viernesTurnoId: 0,
            viernesPaciente: '',
            viernesTratamiento: '',
            // viernesProfesionalId: 0,
            viernesDisponible: false,
            viernesTurnoAnterior: false,
            viernesHabilitado: turnoHabilitado(fecha, index, 
                state.horario.viernesHoraInicio, state.horario.viernesHoraFin),

            sabadoTurnoId: 0,
            sabadoPaciente: '',
            sabadoTratamiento: '',
            // sabadoProfesionalId: 0,
            sabadoDisponible: false,
            sabadoTurnoAnterior: false,
            sabadoHabilitado: turnoHabilitado(fecha, index, 
                state.horario.sabadoHoraInicio, state.horario.sabadoHoraFin),
            readOnly: true,
            _rowVariant: ''
        });
    }

    state.turnos.forEach(turno => {
        /* Turno tomando unicamente la fecha */
        let fechaHoraTurno = new Date(turno.fechaHoraTurno);
        let anioTurno = fechaHoraTurno.getFullYear();
        let mesTurno = fechaHoraTurno.getMonth();
        let diaTurno = fechaHoraTurno.getDate();
        let fechaTurno = new Date(anioTurno, mesTurno, diaTurno);

        /* Dias de diferencia */
        let dias = parseInt((fechaTurno - state.fechaDesde) / (1000 * 60 * 60 * 24), 10);

        let turnoAgenda = state.turnosDisplay.find(t => moment(t.hora).format('HH:mm') == moment(turno.fechaHoraTurno).format('HH:mm'));
        if (turnoAgenda) {
            switch (dias) {
                case 0:
                    turnoAgenda.lunesTurnoId = turno.id;
                    turnoAgenda.lunesPaciente = turno.paciente.trim();
                    turnoAgenda.lunesTratamiento = turno.tratamiento.trim();
                    // turnoAgenda.lunesProfesionalId = turno.profesionalId;
                    turnoAgenda.lunesDisponible = (/true/i).test(turno.disponible);
                    turnoAgenda.lunesTurnoAnterior = (/true/i).test(turno.turnoAnterior);
                    break;
                case 1:
                    turnoAgenda.martesTurnoId = turno.id;
                    turnoAgenda.martesPaciente = turno.paciente.trim();
                    turnoAgenda.martesTratamiento = turno.tratamiento.trim();
                    // turnoAgenda.martesProfesionalId = turno.profesionalId;
                    turnoAgenda.martesDisponible = (/true/i).test(turno.disponible);
                    turnoAgenda.martesTurnoAnterior = (/true/i).test(turno.turnoAnterior);
                    break;
                case 2:
                    turnoAgenda.miercolesTurnoId = turno.id;
                    turnoAgenda.miercolesPaciente = turno.paciente.trim();
                    turnoAgenda.miercolesTratamiento = turno.tratamiento.trim();
                    // turnoAgenda.miercolesProfesionalId = turno.profesionalId;
                    turnoAgenda.miercolesDisponible = (/true/i).test(turno.disponible);
                    turnoAgenda.miercolesTurnoAnterior = (/true/i).test(turno.turnoAnterior);
                    break;
                case 3:
                    turnoAgenda.juevesTurnoId = turno.id;
                    turnoAgenda.juevesPaciente = turno.paciente.trim();
                    turnoAgenda.juevesTratamiento = turno.tratamiento.trim();
                    // turnoAgenda.juevesProfesionalId = turno.profesionalId;
                    turnoAgenda.juevesDisponible = (/true/i).test(turno.disponible);
                    turnoAgenda.juevesTurnoAnterior = (/true/i).test(turno.turnoAnterior);
                    break;
                case 4:
                    turnoAgenda.viernesTurnoId = turno.id;
                    turnoAgenda.viernesPaciente = turno.paciente.trim();
                    turnoAgenda.viernesTratamiento = turno.tratamiento.trim();
                    // turnoAgenda.viernesProfesionalId = turno.profesionalId;
                    turnoAgenda.viernesDisponible = (/true/i).test(turno.disponible);
                    turnoAgenda.viernesTurnoAnterior = (/true/i).test(turno.turnoAnterior);
                    break;
                case 5:
                    turnoAgenda.sabadoTurnoId = turno.id;
                    turnoAgenda.sabadoPaciente = turno.paciente.trim();
                    turnoAgenda.sabadoTratamiento = turno.tratamiento.trim();
                    // turnoAgenda.sabadoProfesionalId = turno.profesionalId;
                    turnoAgenda.sabadoDisponible = (/true/i).test(turno.disponible);
                    turnoAgenda.sabadoTurnoAnterior = (/true/i).test(turno.turnoAnterior);
                    break;
            }
        }
    });
}

/* Turnos - Vista Diaria */
function getTurnosFecha (state) {
    state.turnosDisplay = [];

    /* Validamos los dias no laborables */
    let turnosHabilitados = diaHabilitado(state, state.fechaDesde);

    /* Buscamos la hora inicial en el horario habilitado */
    let fechaHoraInicio = state.horario.lunesHoraInicio;
    let fechaHoraFin = state.horario.lunesHoraFin;

    // switch (moment(state.fechaDesde).weekday()) {
    switch (state.fechaDesde.getDay()) {
        case 1:
            fechaHoraInicio = state.horario.lunesHoraInicio;
            fechaHoraFin = state.horario.lunesHoraFin;
            break;
        case 2:
            fechaHoraInicio = state.horario.martesHoraInicio;
            fechaHoraFin = state.horario.martesHoraFin;
            break;
        case 3:
            fechaHoraInicio = state.horario.miercolesHoraInicio;
            fechaHoraFin = state.horario.miercolesHoraFin;
            break;
        case 4:
            fechaHoraInicio = state.horario.juevesHoraInicio;
            fechaHoraFin = state.horario.juevesHoraFin;
            break;
        case 5:
            fechaHoraInicio = state.horario.viernesHoraInicio;
            fechaHoraFin = state.horario.viernesHoraFin;
            break;
        case 6:
            fechaHoraInicio = state.horario.sabadoHoraInicio;
            fechaHoraFin = state.horario.sabadoHoraFin;
            break;
    }

    let diferenciaMinutos = parseInt(moment(fechaHoraFin).diff(fechaHoraInicio, 'minutes'));
    let cantidadTurnos = parseInt(diferenciaMinutos / 30);

    let inicioHora = parseInt(moment(fechaHoraInicio).format('HH'));
    let inicioMinutos = parseInt(moment(fechaHoraInicio).format('mm'));

    // let fecha = moment.utc(new Date('1900-01-01'))
    //     .add(inicioHora,'hours')
    //     .add(inicioMinutos, 'minutes');
    
    let fecha = new Date(1900, 0, 1, parseInt(inicioHora), parseInt(inicioMinutos));

    for (let index = 0; index < cantidadTurnos; index++) {
        state.turnosDisplay.push({
            hora: moment(fecha).add(index * 30,'minutes'),
            horaDisplay: moment(fecha).add(index * 30,'minutes').format('HH:mm'),
            
            turnoId: 0,
            paciente: '',
            tratamiento: '',
            disponible: false,
            turnoAnterior: false,
            habilitado: turnosHabilitados,
            // habilitado: turnoHabilitado(fecha, index),

            readOnly: true,
            _rowVariant: ''
        });
    }

    state.turnos.forEach(turno => {
        let turnoAgenda = state.turnosDisplay.find(t => moment(t.hora).format('HH:mm') == moment(turno.fechaHoraTurno).format('HH:mm'));
        if (turnoAgenda) {
            turnoAgenda.turnoId = turno.id;
            turnoAgenda.paciente = turno.paciente.trim();
            turnoAgenda.tratamiento = turno.tratamiento.trim();
            // turnoAgenda.lunesProfesionalId = turno.profesionalId;
            turnoAgenda.disponible = (/true/i).test(turno.disponible);
            turnoAgenda.turnoAnterior = (/true/i).test(turno.turnoAnterior);
        }
    });
}

function diaHabilitado(state, dia) {
    if (state.diasNoLaborables) {
        if (state.diasNoLaborables.length != 0) {
            let diaNoLaborable = state.diasNoLaborables
                .find(dnl => moment(new Date(dnl.fechaNoLaborable)).format('YYYY-MM-DD') == moment(dia).format('YYYY-MM-DD'));
            if (diaNoLaborable) {
                return false;
            }
        }
    }
    return true;
}

function turnoHabilitado(fechaHoraTurno, turno, fechaHoraInicio, fechaHoraFin) {
    let horas = parseInt(moment(fechaHoraTurno).add(turno * 30,'minutes').format('HH'));
    let minutos = parseInt(moment(fechaHoraTurno).add(turno * 30,'minutes').format('mm'));
    let totalMinutos = minutos + horas * 60;

    let horasInicioLunes = parseInt(moment(fechaHoraInicio).format('HH'));
    let minutosInicioLunes = parseInt(moment(fechaHoraInicio).format('mm'));
    let totalMinutosInicioLunes = minutosInicioLunes + horasInicioLunes * 60;

    let horasFinLunes = parseInt(moment(fechaHoraFin).format('HH'));
    let minutosFinLunes = parseInt(moment(fechaHoraFin).format('mm'));
    let totalMinutosFinLunes = minutosFinLunes + horasFinLunes * 60;

    if (totalMinutosInicioLunes > totalMinutos || totalMinutosFinLunes <= totalMinutos ) {
        return false;
    }

    return true;
}

const getters = {
    getField,

};

const actions = {
    inicializarAgenda ( { commit } ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_AGENDA');
            resolve();
        }, error => {
            reject(error);
        })            
    },
    getAgenda ( { commit, state } ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_TURNOS');
            
            let salaSelected = false;
            if (state.salaId) {
                if (state.salaId != 0) {
                    salaSelected = true;
                }
            }

            if (salaSelected) {
                let queryString = '?fechaDesde=' + moment(state.fechaDesde).format('YYYY-MM-DD')
                    + '&fechaHasta=' + moment(state.fechaHasta).format('YYYY-MM-DD');

                // let queryString = '?fechaDesde=' + helpers.methods.dateToString(state.fechaDesde)
                //     + '&fechaHasta=' + helpers.methods.dateToString(state.fechaHasta);
                        
                apiAxios.get('turnos/sala/' + state.salaId + queryString)
                    .then(res => {
                        commit('SET_TURNOS', res.data);
                        resolve();
                    })
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        }, error => {
            reject(error);
        })            
    },
    setAgendaCurrent: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('SET_AGENDA_CURRENT');
            resolve();
        }, error => {
            reject(error);
        })            
    },
    setAgendaDiaria: ({ commit }, fecha) => {
        return new Promise((resolve, reject) => {
            commit('SET_AGENDA_DIARIA', fecha);
            resolve();
        }, error => {
            reject(error);
        })            
    },
    setAgendaSemanal: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('SET_AGENDA_SEMANAL');
            resolve();
        }, error => {
            reject(error);
        })            
    },
    getPrevio: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('SET_PERIODO', Boolean(true));
            resolve();
        }, error => {
            reject(error);
        })            
    },
    getProximo: ({ commit }) => {
        return new Promise((resolve, reject) => {
            commit('SET_PERIODO', Boolean(false));
            resolve();            
        }, error => {
            reject(error);
        })            
    },
    setSala: ({commit}, sala) => {
        return new Promise((resolve, reject) => {
            commit('SET_SALA', sala);
            resolve();
        })
    },
    cancelarTurno ( {}, turnoId ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('turnos/' + turnoId + '/delete')
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}