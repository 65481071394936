import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import { getAccesos } from '../../helpers/user_nav';

const state = {
  avatar: '',
  apellido: '',
  apellido2: '',
  nombre: '',
  email: '',
  usuarioRolId: 0,
  avatar: '',
  tokenString: '',

  nav: []
};

const mutations = {
  'LOGIN'(state, params) {
    state.email = params.user.email;
    state.apellido = params.user.apellido;
    state.apellido2 = params.user.apellido2;
    state.nombre = params.user.nombre;
    state.avatar = '';
    state.usuarioRolId = params.user.usuarioRolId;
    state.tokenString = params.tokenString;

    getMenu(state);
  },
  'LOGOUT'(state) {
    state.email = '';
    state.apellido = '';
    state.apellido2 = '';
    state.nombre = '';
    state.avatar = '';
    state.usuarioRolId = 0;
    state.tokenString = '';

    state.nav = [];
  },
  'PERFIL'(state, params) {
    state.apellido = params.apellido.trim();
    state.nombre = params.nombre.trim();
  },
};

const actions = {
  login ( { commit }, credenciales ) {
    return new Promise((resolve, reject) => { 
      commit('LOGOUT');

      apiAxios.post('auth/login', credenciales)
        .then((res) => {
            commit('LOGIN', res.data);

            const now = new Date();
            const expirationDate = new Date(now.getTime() + 3000000);
            localStorage.setItem('sol-email', res.data.user.email);
            localStorage.setItem('sol-token', res.data.tokenString);
            localStorage.setItem('sol-expirationDate', expirationDate);
            
            resolve();
        })
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
  tryLogin: ({commit}) => {
    return new Promise((resolve, reject) => {
      /* Recuperamos los datos del localStorage */  
      let email = localStorage.getItem('sol-email');
      let tokenString = localStorage.getItem('sol-token');
      let expirationDate = new Date(localStorage.getItem('sol-expirationDate', expirationDate));
      let currentDate = new Date();
      
      /* Validamos datos del usuario y la fecha de expiración */
      if (email && tokenString 
          && expirationDate.getTime() > currentDate.getTime()) {
        
        var credenciales = {
          email: email,
          tokenString: tokenString
        }
        apiAxios.post('auth/auto', credenciales) 
          .then(res => {
            commit('LOGIN', res.data);

            /* Actualizamos los datos del token */
            const now = new Date();
            const expirationDate = new Date(now.getTime() + 3000000);
            localStorage.setItem('sol-token', res.data.tokenString);
            localStorage.setItem('sol-expirationDate', expirationDate);

            resolve();
          })
          .catch(error => reject(helpers.methods.getErrorMessage(error)));
        } else {
          reject();
        }
    })
  },  
  logout: ({commit}) => {
    return new Promise((resolve, reject) => {
      commit('LOGOUT');

      localStorage.removeItem('sol-email');
      localStorage.removeItem('sol-token');
      localStorage.removeItem('sol-expirationDate');

      resolve();
    })
  },

  registrar ( {}, params ) {
    return new Promise((resolve, reject) => { 
      apiAxios.post('auth/registro', params)
        .then(() => resolve())
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
  forgotPassword ( {}, params ) {
    return new Promise((resolve, reject) => { 
      apiAxios.post('auth/forgot', params)
        .then(() => resolve())
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },
  resetPassword ( {}, params ) {
    return new Promise((resolve, reject) => { 
      apiAxios.post('auth/reset-password', params)
        .then(() => resolve())
        .catch(error => reject(helpers.methods.getErrorMessage(error)));
    })
  },

};
const getters = {
  loggedIn(state) {
    if (state.email) {
      if (state.email != '') {
        /* Validar password vencido */
        return true;
      }
    }
    return false;
  },
  email (state) {
    return state.email;
  },
  apellido (state) {
    return state.apellido;
  },
  apellido2 (state) {
    return state.apellido2;
  },
  nombre (state) {
    return state.nombre;
  },
  avatar (state) {
    return state.avatar;
  },
  usuarioRolId (state) {
    return state.usuarioRolId;
  },
  tokenString (state) {
    return state.tokenString;
  },
  nav (state) {
    return state.nav;
  },

  tieneAccesoRuta: (state) => (route) => {
    let routePath = route.path;

    // console.log(route);

    if (state.usuarioRolId == 1) {
      return true;
    } else {
      if (route.params) {
        if (route.params.hasOwnProperty('id')) {
          routePath = routePath.replace('/' + route.params.id, '/:id');
        }

        if (route.params.hasOwnProperty('historiaClinicaEntradaId')) {
          routePath = routePath.replace('/' + route.params.historiaClinicaEntradaId, '/:historiaClinicaEntradaId');
        }
      }

      // console.log(routePath);

      let accesos = getAccesos();

      let accesoHabilitado = accesos.find((acceso) => {
        return '/' + acceso.trim() == routePath || '/' + acceso.trim() + '/' == routePath;
      })

      if (accesoHabilitado) {
        return true;
      }
    }

    return false;
  },

  tieneAccesoRutaByPath: (state) => (ruta) => {
    let accesos = getAccesos();

    let accesoHabilitado = accesos.find(acceso => acceso.trim() == ruta.trim());
    if (accesoHabilitado) {
      return true;
    }

    return false;
  },  

  tieneAccesoModulo: (state) => (modulo) => {
    let accesos = getAccesos();

    let accesoHabilitado = accesos.find(acceso => acceso.trim() == modulo.trim());
    if (accesoHabilitado) {
      return true;
    }

    return false;
  },  
};

function getMenu(state) {
  state.nav = [];

  state.nav.push({
    name: 'Inicio',
    url: '/inicio',
    icon: 'icon-home',
  })

  if (tieneAcceso(state, 'agenda')) {
    let parentItem = addNavItem(null, 'Agendas', 'agenda', 'icon-calendar');

    // addNavItemValidated(parentItem, 'Agenda', 'agenda/turnos', 'icon-calendar');
    addNavItemValidated(parentItem, 'Agenda por Profesional', 'agenda/profesionales', 'icon-calendar');
    addNavItemValidated(parentItem, 'Agenda por Sala', 'agenda/salas', 'icon-calendar');
  }

  if (tieneAcceso(state, 'pacientes')) {
    addNavItem(null, 'Pacientes', 'pacientes', 'icon-id-card');
  }

  if (tieneAcceso(state, 'profesionales')) {
    addNavItem(null, 'Profesionales', 'profesionales', 'icon-doctor');
  }

  if (tieneAcceso(state, 'tablas')) {
    let parentItem = addNavItem(null, 'Tablas', 'tablas', 'icon-list');

    addNavItemValidated(parentItem, 'Salas', 'salas', 'icon-list');
    addNavItemValidated(parentItem, 'Areas', 'areas', 'icon-list');
    addNavItemValidated(parentItem, 'Servicios', 'servicios', 'icon-list');
    addNavItemValidated(parentItem, 'Tratamientos', 'tratamientos', 'icon-list');

    // addNavItemValidated(parentItem, 'Salas', 'tablas/salas', 'icon-list');
    // addNavItemValidated(parentItem, 'Areas', 'tablas/areas', 'icon-list');
    // addNavItemValidated(parentItem, 'Servicios', 'tablas/servicios', 'icon-list');
    // addNavItemValidated(parentItem, 'Tratamientos', 'tablas/tratamientos', 'icon-list');
  }  

  if (tieneAcceso(state, 'configuracion')) {
    let parentItem = addNavItem(null, 'Configuración', 'configuracion', 'icon-config');

    addNavItemValidated(parentItem, 'Horario', 'horario/atencion', 'icon-clock');
    addNavItemValidated(parentItem, 'Horario Público', 'horario/publico', 'icon-clock');
    addNavItemValidated(parentItem, 'No Laborables', 'no-laborables', 'icon-calendar');
  }  

  // if (tieneAcceso(state, 'horario')) {
  //   addNavItem(null, 'Horario', 'horario', 'icon-clock');
  // }

  // if (tieneAcceso(state, 'no-laborables')) {
  //   addNavItem(null, 'No Laborables', 'no-laborables', 'icon-calendar');
  // }

  if (tieneAcceso(state, 'seguridad')) {
    let parentItem = addNavItem(null, 'Seguridad', 'seguridad', 'icon-people');

    addNavItemValidated(parentItem, 'Usuarios', 'usuarios', 'icon-people');
    addNavItemValidated(parentItem, 'Usuarios No Habilitados', 'usuarios/no-habilitados', 'icon-people');
    // addNavItemValidated(parentItem, 'Usuarios', 'seguridad/usuarios', 'icon-people');
  }
}

function tieneAcceso (state, navUrl) {
  let accesoHabilitado = false;

  if (state.usuarioRolId == 1) {
    accesoHabilitado = true;
  } else {
    let accesos = getAccesos();

    accesoHabilitado = accesos.find(acceso => acceso.trim() == navUrl);

  }
  return accesoHabilitado;
}

function addNavItem(parent, navItem, navUrl, navIcon) {
  let item = {
    name: navItem,
    url: '/' + navUrl.trim(),
    icon: navIcon
  }

  let addItem = true;
  if (parent) {
    if (!parent.children) {
      parent.children = [];  
    } 
    parent.children.push(item);
    addItem = false;
  }
  if (addItem) {
    state.nav.push(item);
  }

  return item;
}

function addNavItemValidated(parent, navItem, navUrl, navIcon) {
  let item;

  if (tieneAcceso(state, navUrl)) {
    item = {
      name: navItem,
      url: '/' + navUrl.trim(),
      icon: navIcon
    }

    let addItem = true;
    if (parent) {
      if (!parent.children) {
        parent.children = [];  
      } 
      parent.children.push(item);
      addItem = false;
    }
    if (addItem) {
      state.nav.push(item);
    }
  }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}