import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default {
    install(Vue) {
        const connection = new HubConnectionBuilder()
            .withUrl('https://admin.solestic.cl/api/turnos-hub')

            // .withUrl('http://localhost:5000/api/turnos-hub')

            .configureLogging(LogLevel.Information)
            .build();

            
        const turnosHub = new Vue();

        Vue.prototype.$turnosHub = turnosHub;

        connection.on('TurnoAdded', (turno) => {
            // console.log('TurnoAdded');
            // console.log(turno);
            turnosHub.$emit('turno-added', turno);
        })

        connection.on('TurnoDeleted', (turnoId) => {
            turnosHub.$emit('turno-deleted', turnoId);
        })

        connection.on('TurnoUpdated', (turno) => {
            // console.log('TurnoUpdated');
            // console.log(turno);
            turnosHub.$emit('turno-updated', turno);
        })

        let startedPromise = null;

        function start() {
            startedPromise = connection.start()
                .catch(err => {
                    return new Promise((resolve, reject) =>
                        setTimeout(
                            () => 
                                start()
                                    .then(resolve)
                                    .catch(reject),
                                5000
                        ) 
                    );
                });
            
            return startedPromise;
        }

        connection.onclose(() => start() );

        start();
    }


}
