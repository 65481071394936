import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    run: '',
    runDigitoVerificador: '',
    apellido: '',
    apellido2: '',
    nombre: '',
    fechaNacimiento: '1900-01-01',
    fechaNacimientoHabilitada: false,
    estadoCivil: '',
    profesion: '',
    direccion: '',
    comuna: null,
    comunaId: 0,
    comunaNombre: null,
    email: '',
    celular: '',
    inactivo: false,
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, pacienteId) {
        state.id = pacienteId;
        state.run = '';
        state.runDigitoVerificador = '';
        state.apellido = '';
        state.apellido2 = '';
        state.nombre = '';
        state.fechaNacimiento = '1900-01-01';
        state.fechaNacimientoHabilitada = false;
        state.estadoCivil = '';
        state.profesion = '';
        state.direccion = '';
        state.comuna = null;
        state.comunaId = 0;
        state.comunaNombre = null;
        state.email = '';
        state.celular = '';
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_DATOS' (state, paciente) {
        /* Formato del RUN */
        let run = paciente.run.replaceAll('.', '').replaceAll('-', '');
        state.run = run.trim().slice(0, -1);
        state.runDigitoVerificador = run.trim().at(-1);

        state.apellido = paciente.apellido.trim();
        state.apellido2 = paciente.apellido2.trim();
        state.nombre = paciente.nombre.trim();
        state.fechaNacimiento = moment(paciente.fechaNacimiento).format('YYYY-MM-DD') ;
        if (moment(paciente.fechaNacimiento).format('YYYY-MM-DD') == '1900-01-01'){
            state.fechaNacimientoHabilitada = false;
        } else {
            state.fechaNacimientoHabilitada = true;
        }
        state.estadoCivil = paciente.estadoCivil.trim();
        state.profesion = paciente.profesion.trim();
        state.direccion = paciente.direccion.trim();
        state.comuna = {
            id: paciente.comunaId,
            nombre: paciente.comuna
        };
        state.comunaId = paciente.comunaId;
        state.comunaNombre = paciente.comuna.trim();
        state.email = paciente.email.trim();
        state.celular = paciente.celular.trim();
        state.inactivo = (/true/i).test(paciente.inactivo);
        state.rowVersion = paciente.rowVersion;
    },
    'SET_COMUNA' (state, comuna) {
        state.comuna = comuna;
        if (comuna) {
            state.comunaId = comuna.id? comuna.id : 0;
            state.comunaNombre = comuna.nombre? comuna.nombre.trim() : null;
        } else {
            state.comunaId = 0;
            state.comunaNombre = null;
        }
    },
};

const getters = {
    getField,

};

const actions = {
    inicializar ( { commit }, pacienteId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', pacienteId);

            let getDatos = false;
            if (pacienteId) {
                if (pacienteId != 0) {
                    getDatos = true;
                }
            }
            if (getDatos) {
                apiAxios.get('pacientes/' + pacienteId + '/abm' ) 
                .then(res => {
                    commit('SET_DATOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    save ( { commit, state } ) {
        return new Promise((resolve, reject) => {
            let save = Boolean(true);
            let errorMessage = '';

            // if (state.id == 0) {
            //     save = Boolean(false);
            //     errorMessage = 'No hay información para registrar.\nSeleccione los medicamentos a incluir en la nota de pedido';
            // }

            if (save) {
                let fechaNacimiento = '1900-01-01';
                if ((/true/i).test(state.fechaNacimientoHabilitada)) {
                    fechaNacimiento = moment(state.fechaNacimiento).format('YYYY-MM-DD');
                }
                
                /* Guardamos el RUN sin puntos ni guiones (junto con el digito verificador) */
                let run = state.run.trim().replaceAll('.', '').replaceAll('-', '') 
                    + state.runDigitoVerificador.trim().toUpperCase();

                let paciente= {
                    // run: state.run.trim(),
                    run,
                    apellido: state.apellido.trim(),
                    apellido2: state.apellido2.trim(),
                    nombre: state.nombre.trim(),
                    fechaNacimiento,
                    estadoCivil: state.estadoCivil.trim(),
                    profesion: state.profesion.trim(),
                    direccion: state.direccion.trim(),
                    comunaId: state.comunaId,
                    email: state.email.trim(),
                    celular: state.celular.trim(),
                    inactivo: (/true/i).test(state.inactivo) 
                }

                let url = 'pacientes';
                if (state.id != 0) {
                    url += '/' + state.id;
                    paciente.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, paciente)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                reject(errorMessage);
            }
        })
    },
    setComuna: ({commit}, comuna) => {
        return new Promise((resolve, reject) => {
            commit('SET_COMUNA', comuna);
            resolve();
        })
    },


};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}