import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    /* Registro */
    pacientes: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 20,
    totalRegistros: 0,
    totalPaginas: 0,
    /* Filtro */
    run: '',
    nombre: '',
    incluirInactivos: false,

    list: [],
    selectList: [],
    rptPacientes: []    
};

const mutations = {
    updateField,

    'INICIALIZAR_PACIENTES'(state) {
        state.pacientes = [];
    },
    'SET_PACIENTES'(state, pacientes) {
        state.pacientes = pacientes;
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, list) {
        state.list = list;
        state.selectList = list.map(paciente => paciente.nombre.trim());
    },

    'INICIALIZAR_RPT'(state) {
        state.rptPacientes = [];
    },
    'SET_RPT'(state, pacientes) {
        state.rptPacientes = pacientes;
    },
};

const actions = {
    getPacientes: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_PACIENTES');

            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual;

            if (state.run != '') {
                queryString += '&run=' + state.run;
            }
            if (state.nombre != '') {
                queryString += '&nombre=' + state.nombre;
            }
            if ((/true/i).test(state.incluirInactivos)) {
                queryString += '&incluirInactivos=true';
            }

            apiAxios.get('pacientes' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_PACIENTES', res.data)

                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getList: ({commit}, params) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            let queryString = '';
            // if (areaId) {
            //     if (areaId != 0) {
            //         queryString += '?areaId=' + areaId;
            //     }
            // }

            if (params) {
                if ((/true/i).test(params.incluirInactivos)) {
                    if (queryString == '') {
                        queryString = '?incluirInactivos=true';
                    } else {
                        queryString += '&incluirInactivos=true';
                    }
                }
            }

            apiAxios.get('pacientes/list' + queryString)
                .then(res => {
                    commit('SET_LIST', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    preregistrarUsuario ( {}, pacienteId ) {
        return new Promise((resolve, reject) => { 
          apiAxios.post('pacientes/' + pacienteId + '/preregistro')
            .then(() => resolve())
            .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    asociarUsuario ( {}, pacienteId ) {
        return new Promise((resolve, reject) => { 
          apiAxios.post('pacientes/' + pacienteId + '/asociar-usuario')
            .then(() => resolve())
            .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getRptPacientes: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_RPT');
            let queryString = '';

            if (state.run != '') {
                if (queryString == '') {
                    queryString += '?run=' + state.run;
                } else {
                    queryString += '&run=' + state.run;
                }
            }
            if (state.nombre != '') {
                if (queryString == '') {
                    queryString += '?nombre=' + state.nombre;
                } else {
                    queryString += '&nombre=' + state.nombre;
                }
            }
            if ((/true/i).test(state.incluirInactivos)) {
                if (queryString == '') {
                    queryString += '?incluirInactivos=true';
                } else {
                    queryString += '&incluirInactivos=true';
                }
            }

            apiAxios.get('pacientes/rpt' + queryString)
                .then(res => {
                    commit('SET_RPT', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,

    rptPacientes(state) {
        return state.rptPacientes;
    },
    list(state) {
        return state.list;
    },
    selectList(state) {
        return state.selectList;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}