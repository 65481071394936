import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    turnoId: 0,
    pacienteId: 0,
    paciente: '',
    fechaNacimiento: '1900-01-01',
    fechaConsulta: '1900-01-01',
    mediciones: [],

    zonasTratamiento: '',
    antecedentesCardiacos: false,
    enfermedadesInmunologicas: false,
    embarazo: false,
    enfermedadesCutaneas: false,
    diabetes: false,
    cancer: false,
    queloides: false,
    alergias: false,
    alergiasDescripcion: '',
    tratamientosEsteticosActuales: '',
    tratamientosEsteticosAnteriores: '',
    enfermedadesActuales: '',

    motivoConsulta: '',
    examenFisico: '',
    diagnostico: '',
    tratamientoRealizado: '',
    indicaciones: '',

    medicionPeso: 0,
    medicionTalla: 0,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, historiaClinicaEntradaId) {
        state.id = historiaClinicaEntradaId;
        state.turnoId = 0;
        state.pacienteId = 0;
        state.paciente = '';
        state.fechaNacimiento = '1900-01-01';
        state.fechaConsulta = '1900-01-01'; 
        state.mediciones = [];
        state.zonasTratamiento = '';
        state.antecedentesCardiacos = false;
        state.enfermedadesInmunologicas = false;
        state.embarazo = false;
        state.enfermedadesCutaneas = false;
        state.diabetes = false;
        state.cancer = false;
        state.queloides = false;
        state.alergias = false;
        state.alergiasDescripcion = '';
        state.tratamientosEsteticosActuales = '';
        state.tratamientosEsteticosAnteriores = '';
        state.enfermedadesActuales = '';

        state.motivoConsulta = '';
        state.examenFisico = '';
        state.diagnostico = '';
        state.tratamientoRealizado = '';
        state.indicaciones = '';

        state.medicionPeso = 0;
        state.medicionTalla = 0;
    },
    'SET_HISTORIA_CLINICA_ENTRADA' (state, historiaClinicaEntrada) {
        state.turnoId = historiaClinicaEntrada.turnoId;
        state.pacienteId = historiaClinicaEntrada.pacienteId;
        state.paciente = historiaClinicaEntrada.paciente.trim();
        state.fechaNacimiento = moment(historiaClinicaEntrada.fechaNacimiento).format('YYYY-MM-DD') ;
        state.fechaConsulta = moment(historiaClinicaEntrada.fechaConsulta).format('YYYY-MM-DD HH:mm') ;
        state.mediciones = historiaClinicaEntrada.mediciones;

        state.zonasTratamiento = historiaClinicaEntrada.zonasTratamiento.trim();
        state.antecedentesCardiacos = (/true/i).test(historiaClinicaEntrada.antecedentesCardiacos);
        state.enfermedadesInmunologicas = (/true/i).test(historiaClinicaEntrada.enfermedadesInmunologicas);
        state.embarazo = (/true/i).test(historiaClinicaEntrada.embarazo);
        state.enfermedadesCutaneas = (/true/i).test(historiaClinicaEntrada.enfermedadesCutaneas);
        state.diabetes = (/true/i).test(historiaClinicaEntrada.diabetes);
        state.cancer = (/true/i).test(historiaClinicaEntrada.cancer);
        state.queloides = (/true/i).test(historiaClinicaEntrada.queloides);
        state.alergias = (/true/i).test(historiaClinicaEntrada.alergias);
        state.alergiasDescripcion = historiaClinicaEntrada.alergiasDescripcion.trim();
        state.tratamientosEsteticosActuales = historiaClinicaEntrada.tratamientosEsteticosActuales.trim();
        state.tratamientosEsteticosAnteriores = historiaClinicaEntrada.tratamientosEsteticosAnteriores.trim();
        state.enfermedadesActuales = historiaClinicaEntrada.enfermedadesActuales.trim();

        state.motivoConsulta = historiaClinicaEntrada.motivoConsulta.trim();
        state.examenFisico = historiaClinicaEntrada.examenFisico.trim();
        state.diagnostico = historiaClinicaEntrada.diagnostico.trim();
        state.tratamientoRealizado = historiaClinicaEntrada.tratamientoRealizado.trim();
        state.indicaciones = historiaClinicaEntrada.indicaciones.trim();

        state.medicionPeso = parseFloat(historiaClinicaEntrada.medicionPeso);
        state.medicionTalla = parseFloat(historiaClinicaEntrada.medicionTalla);
    },
};

const getters = {
    getField,

    entrada (state) {
        return {
            paciente: state.paciente,
            fechaNacimiento: state.fechaNacimiento,
            fechaConsulta: state.fechaConsulta,
            // mediciones: [],
        
            zonasTratamiento: state.zonasTratamiento,
            antecedentesCardiacos: state.antecedentesCardiacos,
            enfermedadesInmunologicas: state.enfermedadesInmunologicas,
            embarazo: state.embarazo,
            enfermedadesCutaneas: state.enfermedadesCutaneas,
            diabetes: state.diabetes,
            cancer: state.cancer,
            queloides: state.queloides,
            alergias: state.alergias,
            alergiasDescripcion: state.alergiasDescripcion,
            tratamientosEsteticosActuales: state.tratamientosEsteticosActuales,
            tratamientosEsteticosAnteriores: state.tratamientosEsteticosAnteriores,
            enfermedadesActuales: state.enfermedadesActuales,
        
            motivoConsulta: state.motivoConsulta,
            examenFisico: state.examenFisico,
            diagnostico: state.diagnostico,
            tratamientoRealizado: state.tratamientoRealizado,
            indicaciones: state.indicaciones,
        
            medicionPeso: state.medicionPeso,
            medicionTalla: state.medicionTalla,
        }
    } 

};

const actions = {
    getHistoriaClinicaEntrada ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', params.historiaClinicaEntradaId);

            let getDatos = false;

            if (params.historiaClinicaEntradaId) {
                if (params.historiaClinicaEntradaId != 0) {
                    getDatos = true;
                }
            }

            if (getDatos) {
                apiAxios.get('pacientes/' + params.pacienteId + '/historia-clinica/'  + params.historiaClinicaEntradaId) 
                .then(res => {
                    commit('SET_HISTORIA_CLINICA_ENTRADA', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}