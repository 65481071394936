// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import 'core-js/es6/promise'
// import 'core-js/es6/string'
// import 'core-js/es7/array'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import Vuelidate from 'vuelidate'
import moment from 'moment'
import store from './store/store';
import inputFormatter from './mixins/inputFormatter';
import helpers from './mixins/helpers';
import authHeaderInterceptor from './helpers/authHeaderInterceptor';
import TurnosHub from './hubs/turnosHub';

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.mixin(inputFormatter)
Vue.mixin(helpers)

Vue.use(TurnosHub);

Vue.filter('cuit', function (cuit) {
  if (cuit) {
    if ( cuit.trim().length == 11 ){
      var cuitArray = cuit.split(''); 
      var cuitFormated = cuitArray[0] + cuitArray[1]
        + "-"
      var digito = 0
      for(digito = 2; digito < 10; digito++)
      {
          cuitFormated += cuitArray[digito];
      } 
      cuitFormated += "-" + cuitArray[10]
      return cuitFormated
    }
    else
      return cuit
  }
  else
    return ''
})

Vue.filter('run', function (run) {
  if (run) {
    let runValue = run.toString().trim().replaceAll('.', '').replaceAll('-', '');

    let runNumber = runValue.trim().slice(0, -1);
    let runDigitoVerificador = runValue.trim().at(-1);

    return runNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      + '-' + runDigitoVerificador.toString().trim();
  }
  else
    return ''
})

Vue.filter('fechaABM', function (fecha) {
  return moment(fecha).format('YYYY-MM-DD')
})

Vue.filter('fecha', function (fecha) {
  if (moment(fecha).format('DD/MM/YYYY') == '01/01/1900')
    return ''
  else
    return moment(fecha).format('DD/MM/YYYY')
})

Vue.filter('fechaHora', function (fecha) {
  if (moment(fecha).format('DD/MM/YYYY') == '01/01/1900')
    return ''
  else
    return moment(fecha).format('DD/MM/YYYY HH:mm')
})

Vue.filter('hora', function (fecha) {
  if (moment(fecha).format('DD/MM/YYYY') == '01/01/1900')
    return ''
  else
    return moment(fecha).format('HH:mm')
})

Vue.filter('periodo', function (fecha) {
  if (moment(fecha).format('DD/MM/YYYY') == '01/01/1900')
    return ''
  else
    return moment(fecha).format('MM/YYYY')
})

/* En caso de que sea == 01/01/1900, devuelve "No", sino la fecha */
Vue.filter('fechaHoraEstado', function (fecha) {
  if (moment(fecha).format('DD/MM/YYYY') == '01/01/1900')
    return 'No'
  else
    return moment(fecha).format('DD/MM/YYYY HH:mm')
})

Vue.filter('moneda', function (value, moneda) {
    var monedaSimbolo = "$"
    if (moneda)
      monedaSimbolo = moneda
    
    var importe = 0
    if (value)
      importe = parseFloat(value)

    return monedaSimbolo + ' ' + importe.toFixed(2)
})

Vue.filter('numero', function (value, decimales, show) {
  if (value) {
    var floatNumber = parseFloat(value)
    return floatNumber.toFixed(decimales)
  }
  else {
    if (show) {
      var floatNumber = parseFloat(0)
      return floatNumber.toFixed(decimales)
    }
    else
      return ''
  }
})

/* Interceptor - agrega token al header */
authHeaderInterceptor();

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})
