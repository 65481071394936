import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    apellido: '',
    apellido2: '',
    nombre: '',
    email: '',
    celular: '',
    inactivo: false,
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, profesionalId) {
        state.id = profesionalId;
        state.apellido = '';
        state.apellido2 = '';
        state.nombre = '';
        state.email = '';
        state.celular = '';
        state.inactivo = false;
    },
    'SET_DATOS' (state, profesional) {
        state.apellido = profesional.apellido.trim();
        state.apellido2 = profesional.apellido2.trim();
        state.nombre = profesional.nombre.trim();
        state.email = profesional.email.trim();
        state.celular = profesional.celular.trim();
        state.inactivo = profesional.inactivo;

    },
};

const getters = {
    getField,

};

const actions = {
    getProfesional ( { commit }, profesionalId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', profesionalId);

            let getDatos = false;
            if (profesionalId) {
                if (profesionalId != 0) {
                    getDatos = true;
                }
            }
            if (getDatos) {
                apiAxios.get('profesionales/' + profesionalId + '/abm' ) 
                .then(res => {
                    commit('SET_DATOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}