import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    id: 0,
    nombre: '',
    area: null,
    areaId: 0,
    areaNombre: null,
    servicio: null,
    servicioId: 0,
    servicioNombre: null,
    duracionTurno: null,
    precioLista: 0,
    precioOferta: 0,
    requiereProfesional: false,
    inactivo: false,
    rowVersion: null,

    duracionesTurnos: [ 30, 60, 90, 120, 150, 180 ]
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, tratamientoId) {
        state.id = tratamientoId;
        state.nombre = '';
        state.area = null;
        state.areaId = 0;
        state.areaNombre = null;
        state.servicio = null;
        state.servicioId = 0;
        state.servicioNombre = null;
        state.duracionTurno = null;
        state.precioLista = 0;
        state.precioOferta = 0;
        state.requiereProfesional = false;
        state.inactivo = false;
        state.rowVersion = null;
    },
    'SET_DATOS' (state, tratamiento) {
        state.nombre = tratamiento.nombre.trim();
        state.area = {
            id: tratamiento.areaId,
            nombre: tratamiento.area
        };
        state.areaId = tratamiento.areaId;
        state.areaNombre = tratamiento.area.trim();
        state.servicio = {
            id: tratamiento.servicioId,
            nombre: tratamiento.servicio
        };
        state.servicioId = tratamiento.servicioId;
        state.servicioNombre = tratamiento.servicio.trim();
        state.duracionTurno = parseInt(tratamiento.duracionTurno);
        state.precioLista = parseFloat(tratamiento.precioLista);
        state.precioOferta = parseFloat(tratamiento.precioOferta);
        state.requiereProfesional = (/true/i).test(tratamiento.requiereProfesional);
        state.inactivo = (/true/i).test(tratamiento.inactivo);
        state.rowVersion = tratamiento.rowVersion;
    },
    'SET_AREA' (state, area) {
        state.area = area;
        if (area) {
            state.areaId = area.id? area.id : 0;
            state.areaNombre = area.nombre? area.nombre.trim() : null;
        } else {
            state.areaId = 0;
            state.areaNombre = null;
        }
        /* Reinicializamos el servicio */
        state.servicio = null;
        state.servicioId = 0;
        state.servicioNombre = null;
    },
    'SET_SERVICIO' (state, servicio) {
        state.servicio = servicio;
        if (servicio) {
            state.servicioId = servicio.id? servicio.id : 0;
            state.servicioNombre = servicio.nombre? servicio.nombre.trim() : null;
        } else {
            state.servicioId = 0;
            state.servicioNombre = null;
        }
    },
};

const getters = {
    getField,

};

const actions = {
    inicializar ( { commit }, tratamientoId ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', tratamientoId);

            let getDatos = false;
            if (tratamientoId) {
                if (tratamientoId != 0) {
                    getDatos = true;
                }
            }
            if (getDatos) {
                apiAxios.get('tratamientos/' + tratamientoId + '/abm' ) 
                .then(res => {
                    commit('SET_DATOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();    
            }
        }, error => {
            reject(error);
        })            
    },
    save ( { commit, state } ) {
        return new Promise((resolve, reject) => {
            let save = Boolean(true);
            let errorMessage = '';

            // if (state.id == 0) {
            //     save = Boolean(false);
            //     errorMessage = 'No hay información para registrar.\nSeleccione los medicamentos a incluir en la nota de pedido';
            // }

            if (save) {
                let tratamiento= {
                    nombre: state.nombre.trim(),
                    servicioId: parseInt(state.servicioId),
                    duracionTurno: parseInt(state.duracionTurno),
                    precioLista: parseFloat(state.precioLista),
                    precioOferta: parseFloat(state.precioOferta),
                    requiereProfesional: (/true/i).test(state.requiereProfesional),
                    inactivo: (/true/i).test(state.inactivo) 
                }

                let url = 'tratamientos';
                if (state.id != 0) {
                    url += '/' + state.id;
                    tratamiento.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, tratamiento)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
                } else {
                reject(errorMessage);
            }
        })
    },
    setArea: ({commit}, area) => {
        return new Promise((resolve, reject) => {
            commit('SET_AREA', area);
            resolve();
        })
    },
    setServicio: ({commit}, servicio) => {
        return new Promise((resolve, reject) => {
            commit('SET_SERVICIO', servicio);
            resolve();
        })
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}