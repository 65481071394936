import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    /* Registro de usuarios */
    usuarios: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 20,
    totalRegistros: 0,
    totalPaginas: 0,
    /* Filtro */
    nombre: '',
    incluirInactivos: false,

    usuariosList: [],
    usuariosAsignar: [],

    clienteId: 0,
    clienteGrupoId: 0,
    nombreCliente: '',
    usuariosCliente: []
};

const mutations = {
    updateField,

    'INICIALIZAR'(state) {
        state.usuarios = [];
    },
    'SET_USUARIOS'(state, usuarios) {
        state.usuarios = usuarios;
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },
};

const actions = {
    getUsuarios: ( { commit } ) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR');

            var queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual;
            
            if (state.nombre != '') {
                queryString += '&nombre=' + state.nombre.trim();
            }
            if ((/true/i).test(state.incluirInactivos)) {
                queryString += '&incluirInactivos=true';
            }

            apiAxios.get('usuarios' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_USUARIOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })
    },
    sendInvite ( {}, usuarioId ) {
        return new Promise((resolve, reject) => { 
          apiAxios.post('usuarios/' + usuarioId + '/send-invite')
            .then(() => resolve())
            .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
          reject(error);
        })
    },    
};

const getters = {
    getField,

    usuariosList(state) {
        return state.usuariosList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}