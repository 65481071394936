import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    /* Registro */
    comunas: [],
    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 20,
    totalRegistros: 0,
    totalPaginas: 0,
    /* Filtro */
    nombre: '',
    incluirInactivos: false,

    list: [],
    selectList: []
};

const mutations = {
    updateField,

    'INICIALIZAR_COMUNAS'(state) {
        state.comunas = [];
    },
    'SET_COMUNAS'(state, comunas) {
        state.comunas = comunas;
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },

    'INICIALIZAR_LIST'(state) {
        state.list = [];
        state.selectList = [];
    },
    'SET_LIST'(state, list) {
        state.list = list;
        state.selectList = list.map(comuna => comuna.nombre.trim());
    },
};

const actions = {
    getComunas: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_COMUNAS');

            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual

            if (state.nombre != '') {
                queryString += '&nombre=' + state.nombre;
            }

            if ((/true/i).test(state.incluirInactivos)) {
                queryString += '&incluirInactivos=true';
            }

            apiAxios.get('comunas' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_COMUNAS', res.data)

                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
    getList: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_LIST');

            apiAxios.get('comunas/list')
                .then(res => {
                    commit('SET_LIST', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,

    list(state) {
        return state.list;
    },
    selectList(state) {
        return state.selectList;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}