import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';

const state = {
    email: '',
    apellido: '',
    apellido2: '',
    nombre: '',
    avatar: '',
    rowVersion: null,
};

const mutations = {
    updateField,

    'INICIALIZAR_USUARIO'(state) {
        state.email = '';
        state.apellido = '';
        state.apellido2 = '';
        state.nombre = '';
        state.avatar = '';
        state.rowVersion = null;
    },
    'SET_USUARIO'(state, usuario) {
        state.email = usuario.email.trim();
        state.apellido = usuario.apellido.trim();
        state.apellido2 = usuario.apellido2.trim();
        state.nombre = usuario.nombre.trim();
        state.rowVersion = usuario.rowVersion;
    },
};

const getters = {
    getField,
};

const actions = {
    inicializar ( { commit } ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_USUARIO');

            apiAxios.get('usuarios/perfil' ) 
                .then(res => {
                    commit('SET_USUARIO', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            
        })
    },    
    save ( { state, commit } ) {
        return new Promise((resolve, reject) => {
            let usuario= {
                apellido: state.apellido.trim(),
                apellido2: state.apellido2.trim(),
                nombre: state.nombre.trim(),
                // avatar: state.avatar.toUpperCase().trim(),
                rowVersion: state.rowVersion
            }
            
            let url = 'usuarios/perfil';
            apiAxios.post(url, usuario)
                .then(() => {
                    /* Actualizamos el nombre del usuario logueado */
                    let params = {
                        apellido: state.apellido.trim(),
                        nombre: state.nombre.trim()
                    }
                    commit('user/PERFIL', params, { root: true });
                    
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));                
        })
    },
    updatePassword ( { }, params ) {
        return new Promise((resolve, reject) => {
            apiAxios.post('usuarios/reset-password', params)
                .then(() => resolve())
                .catch(error => reject(helpers.methods.getErrorMessage(error)));                
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}