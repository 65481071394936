import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import moment from 'moment';

const state = {
    turnoId: 0,

    agendaSala: false,
    agendaProfesional: false,

    fechaHora: new Date(1900, 0, 1),
    // salaId: 0,
    servicio: null,
    servicioId: 0,
    servicioNombre: null,
    tratamiento: null,
    tratamientoId: 0,
    tratamientoNombre: null,
    sala: null,
    salaId: 0,
    salaNombre: null,
    profesional: null,
    profesionalId: 0,
    profesionalNombre: null,
    paciente: null,
    pacienteId: 0,
    pacienteNombre: null,
    observaciones: '',
    rowVersion: null,

    tratamientoRequiereProfesional: false
};

const mutations = {
    updateField,

    'INICIALIZAR' (state, params) {
        let turnoId = 0;
        if (params.turnoId) {
            if (params.turnoId != 0) {
                turnoId = params.turnoId;
            }
        }
        state.turnoId = turnoId;

        // console.log(state.turnoId, turnoId, params, params.turnoId);

        state.fechaHora = params.fechaHora;
        state.agendaSala = params.agendaSala;
        state.agendaProfesional = params.agendaProfesional;

        let salaId = 0;
        if (params.salaId) {
            if (params.salaId != 0) {
                salaId = params.salaId;
            }
        }

        let profesionalId = 0;
        if (params.profesionalId) {
            if (params.profesionalId != 0) {
                profesionalId = params.profesionalId;
            }
        }

        state.servicio = null;
        state.servicioId = 0;
        state.servicioNombre = null;
        
        state.tratamiento = null;
        state.tratamientoId = 0;
        state.tratamientoNombre = null;

        state.salas = null;
        state.salaId = salaId;
        state.salaNombre = null;

        state.profesional = null;
        state.profesionalId = profesionalId;
        state.profesionalNombre = null;

        state.paciente = null;
        state.pacienteId = 0;
        state.pacienteNombre = null;
        state.observaciones = '';
        state.rowVersion = null;

        state.tratamientoRequiereProfesional = false;
    },
    'SET_TURNO' (state, turno) {
        state.servicioId = turno.servicioId;
        state.servicio = {
            id: turno.servicioId,
            nombre: turno.servicio.trim()
        };
        state.servicioNombre = turno.servicio.trim();
        state.tratamientoId = turno.tratamientoId;
        state.tratamiento = {
            id: turno.tratamientoId,
            nombre: turno.tratamiento.trim()
        };
        state.tratamientoNombre = turno.tratamiento.trim();

        state.pacienteId = turno.pacienteId;
        state.paciente = {
            id: turno.pacienteId,
            nombre: turno.paciente.trim()
        };
        state.pacienteNombre = turno.paciente.trim();

        state.observaciones = turno.observaciones;
        state.rowVersion = turno.rowVersion;
        
        /* Validamos si el tratamiento requiere profesional */
        state.tratamientoRequiereProfesional = (/true/i).test(turno.tratamientoRequiereProfesional);

        /* Modifica sala y/o profesional */
        state.salaId = 0;
        state.sala = null;
        state.salaNombre = null;

        state.profesionalId = 0;
        state.profesional = null;
        state.profesionalNombre = null;
    },
    'SET_SERVICIO' (state, servicio) {
        state.servicio = servicio;
        if (servicio) {
            state.servicioId = servicio.id? servicio.id : 0;
            state.servicioNombre = servicio.nombre? servicio.nombre.trim() : null;
        } else {
            state.servicioId = 0;
            state.servicioNombre = null;
        }
        
        /* Reinicializamos el tratamiento */
        state.tratamiento = null;
        state.tratamientoId = 0;
        state.tratamientoNombre = null;

        state.tratamientoRequiereProfesional = false;

        /* Reinicializamos las salas (si no es un turno por agenda de salas) */
        if (!(/true/i).test(state.agendaSala)) {
            state.sala = null;
            state.salaId = 0;
            state.salaNombre = null;
        }
        /* Reinicializamos los profesionales (si no es un turno por agenda de profesionales) */
        if (!(/true/i).test(state.agendaProfesional)) {
            state.profesional = null;
            state.profesionalId = 0;
            state.profesionalNombre = null;
        }
    },
    'SET_TRATAMIENTO' (state, tratamiento) {
        state.tratamiento = tratamiento;
        if (tratamiento) {
            state.tratamientoId = tratamiento.id? tratamiento.id : 0;
            state.tratamientoNombre = tratamiento.nombre? tratamiento.nombre.trim() : null;

            state.tratamientoRequiereProfesional = (/true/i).test(tratamiento.requiereProfesional);
        } else {
            state.tratamientoId = 0;
            state.tratamientoNombre = null;

            state.tratamientoRequiereProfesional = false;
        }

        /* Reinicializamos las salas (si no es un turno por agenda de salas) */
        if (!(/true/i).test(state.agendaSala)) {
            state.sala = null;
            state.salaId = 0;
            state.salaNombre = null;
        }
        /* Reinicializamos los profesionales (si no es un turno por agenda de profesionales) */
        if (!(/true/i).test(state.agendaProfesional)) {
            state.profesional = null;
            state.profesionalId = 0;
            state.profesionalNombre = null;
        }
    },
    'SET_SALA' (state, sala) {
        state.sala = sala;
        if (sala) {
            state.salaId = sala.id? sala.id : 0;
            state.salaNombre = sala.nombre? sala.nombre.trim() : null;
        } else {
            state.salaId = 0;
            state.salaNombre = null;
        }
    },
    'SET_PROFESIONAL' (state, profesional) {
        state.profesional = profesional;
        if (profesional) {
            state.profesionalId = profesional.id? profesional.id : 0;
            state.profesionalNombre = profesional.nombre? profesional.nombre.trim() : null;
        } else {
            state.profesionalId = 0;
            state.profesionalNombre = null;
        }
    },
    'SET_PACIENTE' (state, paciente) {
        state.paciente = paciente;
        if (paciente) {
            state.pacienteId = paciente.id? paciente.id : 0;
            state.pacienteNombre = paciente.nombre? paciente.nombre.trim() : null;
        } else {
            state.pacienteId = 0;
            state.pacienteNombre = null;
        }
    },
};

const getters = {
    getField,

    turno(state) {
        return {
            paciente: state.pacienteNombre
        }
    }
};

const actions = {
    inicializar ( { commit }, params ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR', params);

            let getTurno = false;

            if (params) {
                if (params.turnoId) {
                    if (params.turnoId != 0) {
                        getTurno = true;
                    }
                }
            }

            if (getTurno) {
                apiAxios.get('turnos/' + params.turnoId + '/abm')
                .then(res => {
                    commit('SET_TURNO', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            } else {
                resolve();
            }
        }, error => {
            reject(error);
        })            
    },
    save ( { state } ) {
        return new Promise((resolve, reject) => {
            let save = Boolean(true);
            let errorMessage = '';

            if (save) {
                let turno= {
                    fechaHoraTurno: moment(state.fechaHora).format('YYYY-MM-DD HH:mm'),
                    tratamientoId: state.tratamientoId,
                    salaId: state.salaId,
                    profesionalId: state.profesionalId,
                    pacienteId: state.pacienteId,
                    observaciones: state.observaciones.trim()
                }
                
                let url = 'turnos';

                if (state.turnoId != 0) {
                    url += '/' + state.turnoId;
                    turno.rowVersion = state.rowVersion;
                }

                apiAxios.post(url, turno)
                    .then(() => resolve())
                    .catch(error => reject(helpers.methods.getErrorMessage(error)));
                } else {
                reject(errorMessage);
            }
        })
    },
    setServicio: ({commit}, servicio) => {
        return new Promise((resolve, reject) => {
            commit('SET_SERVICIO', servicio);
            resolve();
        })
    },
    setTratamiento: ({commit}, tratamiento) => {
        return new Promise((resolve, reject) => {
            commit('SET_TRATAMIENTO', tratamiento);
            resolve();
        })
    },
    setSala: ({commit}, sala) => {
        return new Promise((resolve, reject) => {
            commit('SET_SALA', sala);
            resolve();
        })
    },
    setProfesional: ({commit}, profesional) => {
        return new Promise((resolve, reject) => {
            commit('SET_PROFESIONAL', profesional);
            resolve();
        })
    },
    setPaciente: ({commit}, paciente) => {
        return new Promise((resolve, reject) => {
            commit('SET_PACIENTE', paciente);
            resolve();
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}