import { getField, updateField } from 'vuex-map-fields';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import moment from 'moment';

const state = {
    /* Registro */
    turnos: [],

    /* Paginacion */
    paginaActual: 1,
    registrosPorPagina: 10,
    totalRegistros: 0,
    totalPaginas: 0,

    /* Filtro */
    fechaDesde: moment(new Date()).format('YYYY-MM-DD'),
    fechaHasta: moment(new Date()).format('YYYY-MM-DD'),
    sala: {
        id: 0,
        nombre: null
    },
    area: {
        id: 0,
        nombre: null
    },
    servicio: {
        id: 0,
        nombre: null
    },
    tratamiento: {
        id: 0,
        nombre: null
    },
    paciente: {
        id: 0,
        nombre: null
    },
    // incluirInactivos: false,
};

const mutations = {
    updateField,

    'INICIALIZAR_TURNOS'(state) {
        state.turnos = [];
    },
    'SET_TURNOS'(state, turnos) {
        state.turnos = turnos;
    },
    'SET_PAGINATION'(state, params) {
        state.paginaActual = params.paginaActual;
        state.registrosPorPagina = params.registrosPorPagina;
        state.totalRegistros = params.totalRegistros;
        state.totalPaginas = params.totalPaginas;
    },
};

const actions = {
    getTurnos: ({commit}) => {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_TURNOS');

            let queryString = '?pageSize=' + state.registrosPorPagina
                + '&pageNumber=' + state.paginaActual
                + '&fechaDesde=' + moment(state.fechaDesde).format('YYYY-MM-DD')
                + '&fechaHasta=' + moment(state.fechaHasta).format('YYYY-MM-DD');

            if (state.sala) {
                if (state.sala.id) {
                    if (state.sala.id != 0) {
                        queryString += '&salaId=' + state.sala.id;
                    }
                }
            }
    
            if (state.area) {
                if (state.area.id) {
                    if (state.area.id != 0) {
                        queryString += '&areaId=' + state.area.id;
                    }
                }
            }

            if (state.servicio) {
                if (state.servicio.id) {
                    if (state.servicio.id != 0) {
                        queryString += '&servicioId=' + state.servicio.id;
                    }
                }
            }

            if (state.tratamiento) {
                if (state.tratamiento.id) {
                    if (state.tratamiento.id != 0) {
                        queryString += '&tratamientoId=' + state.tratamiento.id;
                    }
                }
            }

            if (state.paciente) {
                if (state.paciente.id) {
                    if (state.paciente.id != 0) {
                        queryString += '&pacienteId=' + state.paciente.id;
                    }
                }
            }

            apiAxios.get('turnos' + queryString)
                .then(res => {
                    if (res.headers.pagination){
                        const pagination= JSON.parse(res.headers.pagination)
                        var params = {
                            paginaActual: pagination.currentPage,
                            registrosPorPagina: pagination.itemsPerPage,
                            totalRegistros: pagination.totalItems,
                            totalPaginas: pagination.totalPages,
                        }    
                        commit('SET_PAGINATION', params);
                    }
                    commit('SET_TURNOS', res.data)
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        })
    },
};

const getters = {
    getField,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}