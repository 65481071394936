import store from '../store/store'

export function getAccesos() {
    let accesos = [];
    switch (store.getters['user/usuarioRolId']) {
        case 0:
            accesos = [
                'inicio',
            ]
            break;

        case 1: /* Superadministrador */ 

        case 2: /* Administracion */
            accesos = [
                'inicio',

                'agenda',
                // 'agenda/turnos',
                'agenda/turnos',
                'agenda/salas',
                'agenda/profesionales',
                
                'pacientes',
                'pacientes/add',
                'pacientes/:id/edit',
                'pacientes/:id',
                'pacientes/:id/historia-clinica',
                'pacientes/:id/historia-clinica/add',
                'pacientes/:id/historia-clinica/:historiaClinicaEntradaId/edit',
                'pacientes/:id/historia-clinica/:historiaClinicaEntradaId',

                'pacientes[antecedentes]',

                'profesionales',
                'profesionales/add',
                'profesionales/:id',
                'profesionales/:id/edit',

                // 'tablas',
                // 'tablas/salas',
                // 'tablas/salas/add',
                // 'tablas/salas/:id/edit',
                // 'tablas/areas',
                // 'tablas/areas/add',
                // 'tablas/areas/:id/edit',
                // 'tablas/servicios',
                // 'tablas/servicios/add',
                // 'tablas/servicios/:id/edit',
                // 'tablas/tratamientos',
                // 'tablas/tratamientos/add',
                // 'tablas/tratamientos/:id/edit',

                'tablas',
                'salas',
                'salas/add',
                'salas/:id/edit',
                'areas',
                'areas/add',
                'areas/:id/edit',
                'servicios',
                'servicios/add',
                'servicios/:id/edit',
                'tratamientos',
                'tratamientos/add',
                'tratamientos/:id',
                'tratamientos/:id/edit',
                
                'configuracion',
                'horario',
                'horario/atencion',
                'horario/publico',
                'no-laborables',
                'no-laborables/api',

                'seguridad',
                'usuarios',
                'usuarios/no-habilitados',
                'usuarios/:id/ABM',
                'usuarios/:id',

                // 'seguridad/usuarios',
                // 'seguridad/usuarios/:id/ABM',
                // 'seguridad/usuarios/:id',
            ]
            break;

        case 3: /* Secretaria */
            accesos = [
                'inicio',

                'agenda',
                // 'agenda/turnos',
                'agenda/turnos',
                'agenda/salas',
                
                'pacientes',
                'pacientes/add',
                'pacientes/:id/edit',
                'pacientes/:id',

                'profesionales',
                'profesionales/:id',

                'tablas',
                'salas',
                // 'tablas/salas/add',
                // 'tablas/salas/:id/edit',
                'areas',
                // 'tablas/areas/add',
                // 'tablas/areas/:id/edit',
                'servicios',
                // 'tablas/servicios/add',
                // 'tablas/servicios/:id/edit',
                'tratamientos',
                // 'tablas/tratamientos/add',
                // 'tablas/tratamientos/:id/edit',

                'seguridad',
                // 'seguridad/usuarios',
                'usuarios/no-habilitados',
                // 'seguridad/usuarios/:id/ABM',
                // 'seguridad/usuarios/:id',
            ]
            break;
    }
    
    /* Accesos disponibles para todos los usuarios */
    // accesos.push('perfil');
    // accesos.push('seguridad/usuarios/self-pass');
    accesos.push('usuarios/perfil');
    accesos.push('usuarios/pass');

    return accesos;
}
