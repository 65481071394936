import { getField, updateField } from 'vuex-map-fields';
import moment from 'moment';
import apiAxios from '../../services/apiAxios';
import helpers from '../../mixins/helpers';
import turnos from './turnos';

const state = {
    fechaDesde: null,
    fechaHasta: null,
    // fechaDesde: moment(addDays(new Date(), (-1 * (new Date().getDay() - 1)) )).format('YYYY-MM-DD'),
    // fechaHasta: moment(addDays(new Date(), (6 - (new Date().getDay())) )).format('YYYY-MM-DD'),

    turnos: [],
    turnosDisplay: [],
    diasNoLaborables: [],
    horario: {},
    salas: [],

    duracionTurno: 30,
    showPaciente: true
};

const mutations = {
    updateField,

    'INICIALIZAR_TURNOS' (state) {
        let fechaDesde = helpers.methods.addDays(new Date(), (-1 * (new Date().getDay() - 1)));
        let anioDesde = fechaDesde.getFullYear();
        let mesDesde = fechaDesde.getMonth();
        let diaDesde = fechaDesde.getDate();
        state.fechaDesde = new Date(anioDesde, mesDesde, diaDesde);
        
        let fechaHasta = helpers.methods.addDays(new Date(), (6 - (new Date().getDay())));
        let anioHasta = fechaHasta.getFullYear();
        let mesHasta = fechaHasta.getMonth();
        let diaHasta = fechaHasta.getDate();
        state.fechaHasta = new Date(anioHasta, mesHasta, diaHasta);

        state.turnos = [];
        state.turnosDisplay = [];
        state.horario = {}; 
        state.diasNoLaborables = [];
        state.salas = [];
    },
    'SET_TURNOS' (state, agenda) {
        state.turnos = agenda.turnos;
        state.horario = agenda.horario;
        state.diasNoLaborables = agenda.diasNoLaborables;
        state.salas = agenda.salas;

        getTurnosPeriodo(state);
    },
    'SET_SEMANA' (state, previa) {
        if ((/true/i).test(previa)) {
            state.fechaDesde = helpers.methods.addDays(state.fechaDesde, -7);
            state.fechaHasta = helpers.methods.addDays(state.fechaHasta, -7);
            // state.fechaDesde = moment.utc(addDays(state.fechaDesde, -7)).format('YYYY-MM-DD');
            // state.fechaHasta = moment.utc(addDays(state.fechaHasta, -7)).format('YYYY-MM-DD');
        } else {
            state.fechaDesde = helpers.methods.addDays(state.fechaDesde, 7);
            state.fechaHasta = helpers.methods.addDays(state.fechaHasta, 7);
            // state.fechaDesde = moment.utc(addDays(state.fechaDesde, 7)).format('YYYY-MM-DD');
            // state.fechaHasta = moment.utc(addDays(state.fechaHasta, 7)).format('YYYY-MM-DD');
        }
    },
    'ADD_TURNO' (state, turno) {
        let itemKey = state.turnos.findIndex(turnoActual => turnoActual.id == turno.id);
        if (itemKey == -1) {
            let cantidadTurnos = parseInt(turno.duracionTurno) / 30;
            let fechaHoraInicio = turno.fechaHoraTurno;
            let duracionTurno = 30;
    
            for (let index = 0; index < cantidadTurnos; index++) {
                let turnoSala = {
                    ...turno,
                    fechaHoraTurno: moment(fechaHoraInicio).add(index * duracionTurno,'minutes')
                }
                state.turnos.push(turnoSala);
            }
    
            getTurnosPeriodo(state);
        }
    },
    'UPDATE_TURNO' (state, turno) {
        /* Eliminamos la referencia al turno original */
        state.turnos = state.turnos.filter(turnoActual => turnoActual.id != turno.id);

        /* Agregamos las nuevas referencias */
        let cantidadTurnos = parseInt(turno.duracionTurno) / 30;
        let fechaHoraInicio = turno.fechaHoraTurno;
        let duracionTurno = 30;

        for (let index = 0; index < cantidadTurnos; index++) {
            let turnoSala = {
                ...turno,
                fechaHoraTurno: moment(fechaHoraInicio).add(index * duracionTurno,'minutes')
            }
            state.turnos.push(turnoSala);
        }

        getTurnosPeriodo(state);
    },
    'REMOVE_TURNO' (state, turnoId) {
        state.turnos = state.turnos.filter(turno => turno.id != turnoId);

        getTurnosPeriodo(state);
    },
};

function getTurnosPeriodo (state) {
    state.turnosDisplay = [];

    // let lunes = moment(state.fechaDesde).format('YYYY-MM-DD');
    // let martes = moment(state.fechaDesde).add(1, 'days').format('YYYY-MM-DD');
    // let miercoles = moment(state.fechaDesde).add(2, 'days').format('YYYY-MM-DD');
    // let jueves = moment(state.fechaDesde).add(3, 'days').format('YYYY-MM-DD');
    // let viernes = moment(state.fechaDesde).add(4, 'days').format('YYYY-MM-DD');
    // let sabado = moment(state.fechaDesde).add(5, 'days').format('YYYY-MM-DD');

    let lunes = state.fechaDesde;
    let martes = helpers.methods.addDays(state.fechaDesde, 1);
    let miercoles = helpers.methods.addDays(state.fechaDesde, 2);
    let jueves = helpers.methods.addDays(state.fechaDesde, 3);
    let viernes = helpers.methods.addDays(state.fechaDesde, 4);
    let sabado = helpers.methods.addDays(state.fechaDesde, 5);

    /* Buscamos la hora inicial en el horario habilitado */
    let fechaHoraInicio = state.horario.lunesHoraInicio;
    let fechaHoraFin = state.horario.lunesHoraFin;

    if (moment(fechaHoraInicio).isAfter(state.horario.martesHoraInicio)) {
        fechaHoraInicio = state.horario.martesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.miercolesHoraInicio)) {
        fechaHoraInicio = state.horario.miercolesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.juevesHoraInicio)) {
        fechaHoraInicio = state.horario.juevesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.viernesHoraInicio)) {
        fechaHoraInicio = state.horario.viernesHoraInicio;
    }
    if (moment(fechaHoraInicio).isAfter(state.horario.sabadoHoraInicio)) {
        fechaHoraInicio = state.horario.sabadoHoraInicio;
    }

    if (moment(fechaHoraFin).isBefore(state.horario.martesHoraFin)) {
        fechaHoraFin = state.horario.martesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.miercolesHoraFin)) {
        fechaHoraFin = state.horario.miercolesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.juevesHoraFin)) {
        fechaHoraFin = state.horario.juevesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.viernesHoraFin)) {
        fechaHoraFin = state.horario.viernesHoraFin;
    }
    if (moment(fechaHoraFin).isBefore(state.horario.sabadoHoraFin)) {
        fechaHoraFin = state.horario.sabadoHoraFin;
    }

    let diferenciaMinutos = parseInt(moment(fechaHoraFin).diff(fechaHoraInicio, 'minutes'));
    let cantidadTurnos = parseInt(diferenciaMinutos / 30);

    let inicioHora = parseInt(moment(fechaHoraInicio).format('HH'));
    let inicioMinutos = parseInt(moment(fechaHoraInicio).format('mm'));

    let horaInicio = moment.utc(new Date('1900-01-01'))
        .add(inicioHora,'hours')
        .add(inicioMinutos, 'minutes');

    for (let index = 0; index < cantidadTurnos; index++) {
        state.turnosDisplay.push({
            key: moment(horaInicio).add(index * state.duracionTurno,'minutes').valueOf(),

            hora: moment(horaInicio).add(index * state.duracionTurno,'minutes'),
            horaDisplay: moment(horaInicio).add(index * state.duracionTurno,'minutes').format('HH:mm'),
            ultimoTurno: index == cantidadTurnos - 1? true : false,

            // hora: moment(fecha).add(index* 30,'minutes'),
            // horaDisplay: moment(fecha).add(index* 30,'minutes').format('HH:mm'),
            
            lunesTurnos: [],
            lunesHabilitado: turnoHabilitado(lunes, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                state.horario.lunesHoraInicio, state.horario.lunesHoraFin),

            martesTurnos: [],
            martesHabilitado: turnoHabilitado(martes, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                state.horario.martesHoraInicio, state.horario.martesHoraFin),
    
            miercolesTurnos: [],
            miercolesHabilitado: turnoHabilitado(miercoles, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                state.horario.miercolesHoraInicio, state.horario.miercolesHoraFin),

            juevesTurnos: [],
            juevesHabilitado: turnoHabilitado(jueves, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                state.horario.juevesHoraInicio, state.horario.juevesHoraFin),

            viernesTurnos: [],
            viernesHabilitado: turnoHabilitado(viernes, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                state.horario.viernesHoraInicio, state.horario.viernesHoraFin),

            sabadoTurnos: [],
            sabadoHabilitado: turnoHabilitado(sabado, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                state.horario.sabadoHoraInicio, state.horario.sabadoHoraFin),

            _cellVariants: { 
                lunes: turnoHabilitado(lunes, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                    state.horario.lunesHoraInicio, state.horario.lunesHoraFin) ? '' : 'danger' ,
                martes: turnoHabilitado(martes, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                    state.horario.lunesHoraInicio, state.horario.lunesHoraFin) ? '' : 'danger' ,
                miercoles: turnoHabilitado(miercoles, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                    state.horario.lunesHoraInicio, state.horario.lunesHoraFin) ? '' : 'danger' ,
                jueves: turnoHabilitado(jueves, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                    state.horario.lunesHoraInicio, state.horario.lunesHoraFin) ? '' : 'danger' ,
                viernes: turnoHabilitado(viernes, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                    state.horario.lunesHoraInicio, state.horario.lunesHoraFin) ? '' : 'danger' ,
                sabado: turnoHabilitado(sabado, moment(horaInicio).add(index * state.duracionTurno,'minutes'), 
                    state.horario.lunesHoraInicio, state.horario.lunesHoraFin) ? '' : 'danger' , }
        });
    }

    state.turnos.forEach(turno => {
        /* Turno tomando unicamente la fecha */
        let fechaHoraTurno = new Date(turno.fechaHoraTurno);
        let anioTurno = fechaHoraTurno.getFullYear();
        let mesTurno = fechaHoraTurno.getMonth();
        let diaTurno = fechaHoraTurno.getDate();
        let fechaTurno = new Date(anioTurno, mesTurno, diaTurno);

        /* Dias de diferencia */
        let dias = parseInt((fechaTurno - state.fechaDesde) / (1000 * 60 * 60 * 24), 10);

        let turnoAgenda = state.turnosDisplay.find(t => moment(t.hora).format('HH:mm') == moment(turno.fechaHoraTurno).format('HH:mm'));
        if (turnoAgenda) {
            switch (dias) {
                case 0:
                    turnoAgenda.lunesTurnos.push(turno)
                    break;
                case 1:
                    turnoAgenda.martesTurnos.push(turno)
                    break;
                case 2:
                    turnoAgenda.miercolesTurnos.push(turno)
                    break;
                case 3:
                    turnoAgenda.juevesTurnos.push(turno)
                    break;
                case 4:
                    turnoAgenda.viernesTurnos.push(turno)
                    break;
                case 5:
                    turnoAgenda.sabadoTurnos.push(turno)
                    break;
            }
        }
    });
}

function turnoHabilitado(dia, horaTurno, horaInicio, horaFin) {
    /* Validamos los dias no laborables */
    if (state.diasNoLaborables) {
        if (state.diasNoLaborables.length != 0) {
            let diaNoLaborable = state.diasNoLaborables
                .find(dnl => moment(new Date(dnl.fechaNoLaborable)).format('YYYY-MM-DD') == moment(dia).format('YYYY-MM-DD'));
            if (diaNoLaborable) {
                return false;
            }
        }
    }

    /* Validamos la hora del turno segun el horario de inicio y fin del día */
    let horas = parseInt(moment(horaTurno).format('HH'));
    let minutos = parseInt(moment(horaTurno).format('mm'));
    let totalMinutos = minutos + horas * 60;

    let horasInicioDia = parseInt(moment(horaInicio).format('HH'));
    let minutosInicioDia = parseInt(moment(horaInicio).format('mm'));
    let totalMinutosInicioDia = minutosInicioDia + horasInicioDia * 60;

    let horasFinDia = parseInt(moment(horaFin).format('HH'));
    let minutosFinDia = parseInt(moment(horaFin).format('mm'));
    let totalMinutosFinDia = minutosFinDia + horasFinDia * 60;

    if (totalMinutosInicioDia > totalMinutos || totalMinutosFinDia <= totalMinutos ) {
        return false;
    }

    return true;
}

const getters = {
    getField,

};

const actions = {
    inicializarTurnos ( { commit } ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_TURNOS');
            resolve();
        }, error => {
            reject(error);
        })            
    },
    getAgenda ( { commit, state } ) {
        return new Promise((resolve, reject) => {
            commit('INICIALIZAR_TURNOS');
            
            let queryString = '?fechaDesde=' + moment(state.fechaDesde).format('YYYY-MM-DD')
                + '&fechaHasta=' + moment(state.fechaHasta).format('YYYY-MM-DD');

            // let queryString = '?fechaDesde=' + helpers.methods.dateToString(state.fechaDesde)
            //     + '&fechaHasta=' + helpers.methods.dateToString(state.fechaHasta);

            apiAxios.get('turnos/salas' + queryString)
                .then(res => {
                    commit('SET_TURNOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })            
    },
    semanaPrevia: ({ commit, state }) => {
        return new Promise((resolve, reject) => {
            commit('SET_SEMANA', Boolean(true));
            
            // let queryString = '?fechaDesde=' + helpers.methods.dateToString(state.fechaDesde)
            //     + '&fechaHasta=' + helpers.methods.dateToString(state.fechaHasta);

            let queryString = '?fechaDesde=' + moment(state.fechaDesde).format('YYYY-MM-DD')
                + '&fechaHasta=' + moment(state.fechaHasta).format('YYYY-MM-DD');
                    
            apiAxios.get('turnos/salas' + queryString)
                .then(res => {
                    commit('SET_TURNOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
        }, error => {
            reject(error);
        })            
    },
    semanaProxima: ({ commit, state }) => {
        return new Promise((resolve, reject) => {
            commit('SET_SEMANA', Boolean(false));
            
            // let queryString = '?fechaDesde=' + helpers.methods.dateToString(state.fechaDesde)
            //     + '&fechaHasta=' + helpers.methods.dateToString(state.fechaHasta);

            let queryString = '?fechaDesde=' + moment(state.fechaDesde).format('YYYY-MM-DD')
                + '&fechaHasta=' + moment(state.fechaHasta).format('YYYY-MM-DD');
                    
            apiAxios.get('turnos/salas' + queryString)
                .then(res => {
                    commit('SET_TURNOS', res.data);
                    resolve();
                })
                .catch(error => reject(helpers.methods.getErrorMessage(error)));
            }, error => {
            reject(error);
        })            
    },
    addTurno: ({ commit }, turno) => {
        return new Promise((resolve, reject) => {
            commit('ADD_TURNO', turno);
            resolve();
        })            
    },
    updateTurno: ({ commit }, turno) => {
        return new Promise((resolve, reject) => {
            commit('UPDATE_TURNO', turno);
            resolve();
        })            
    },
    removeTurno: ({ commit }, turnoId) => {
        return new Promise((resolve, reject) => {
            commit('REMOVE_TURNO', turnoId);
            resolve();
        })            
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}